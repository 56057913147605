
.animated {
  animation-fill-mode: both;
  -moz-animation-fill-mode: both;
  -webkit-animation-fill-mode: both;

  animation-duration: 1s;
  -moz-animation-duration: 1s;
  -webkit-animation-duration: 1s;

  &.hinge {
    animation-duration: 1s;
    -moz-animation-duration: 1s;
    -webkit-animation-duration: 1s;
  }
}

@-o-keyframes flash {
  0%, 50%, 100% {
    opacity: 1;
  }
  25%, 75% {
    opacity: 0;
  }
}
@-moz-keyframes flash {
  0%, 50%, 100% {
    opacity: 1;
  }
  25%, 75% {
    opacity: 0;
  }
}
@-webkit-keyframes flash {
  0%, 50%, 100% {
    opacity: 1;
  }
  25%, 75% {
    opacity: 0;
  }
}
@keyframes flash {
  0%, 50%, 100% {
    opacity: 1;
  }
  25%, 75% {
    opacity: 0;
  }
}

.flash {
  animation-name: flash;
   -moz-animation-name: flash;
  -webkit-animation-name: flash;
}

@-webkit-keyframes shake {
  0%, 100% {
    -webkit-transform: translateX(0);
  }

  10%, 30%, 50%, 70%, 90% {
    -webkit-transform: translateX(-10px);
  }

  20%, 40%, 60%, 80% {
    -webkit-transform: translateX(10px);
  }
}

@-moz-keyframes shake {
  0%, 100% {
    -moz-transform: translateX(0);
  }

  10%, 30%, 50%, 70%, 90% {
    -moz-transform: translateX(-10px);
  }

  20%, 40%, 60%, 80% {
    -moz-transform: translateX(10px);
  }
}

@-o-keyframes shake {
  0%, 100% {
    -o-transform: translateX(0);
  }

  10%, 30%, 50%, 70%, 90% {
    -o-transform: translateX(-10px);
  }

  20%, 40%, 60%, 80% {
    -o-transform: translateX(10px);
  }
}

@keyframes shake {
  0%, 100% {
    transform: translateX(0);
  }

  10%, 30%, 50%, 70%, 90% {
    transform: translateX(-10px);
  }

  20%, 40%, 60%, 80% {
    transform: translateX(10px);
  }
}

.shake {
  -webkit-animation-name: shake;
  -moz-animation-name: shake;
  -o-animation-name: shake;
  animation-name: shake;
}

@-webkit-keyframes bounce {
  0%, 20%, 50%, 80%, 100% {
    -webkit-transform: translateY(0);
  }

  40% {
    -webkit-transform: translateY(-30px);
  }

  60% {
    -webkit-transform: translateY(-15px);
  }
}

@-moz-keyframes bounce {
  0%, 20%, 50%, 80%, 100% {
    -moz-transform: translateY(0);
  }

  40% {
    -moz-transform: translateY(-30px);
  }

  60% {
    -moz-transform: translateY(-15px);
  }
}

@-o-keyframes bounce {
  0%, 20%, 50%, 80%, 100% {
    -o-transform: translateY(0);
  }

  40% {
    -o-transform: translateY(-30px);
  }

  60% {
    -o-transform: translateY(-15px);
  }
}

@keyframes bounce {
  0%, 20%, 50%, 80%, 100% {
    transform: translateY(0);
  }

  40% {
    transform: translateY(-30px);
  }

  60% {
    transform: translateY(-15px);
  }
}

.bounce {
  -webkit-animation-name: bounce;
  -moz-animation-name: bounce;
  -o-animation-name: bounce;
  animation-name: bounce;
}

@-webkit-keyframes tada {
  0% {
    -webkit-transform: scale(1);
  }

  10%, 20% {
    -webkit-transform: scale(0.9) rotate(-3deg);
  }

  30%, 50%, 70%, 90% {
    -webkit-transform: scale(1.1) rotate(3deg);
  }

  40%, 60%, 80% {
    -webkit-transform: scale(1.1) rotate(-3deg);
  }

  100% {
    -webkit-transform: scale(1) rotate(0);
  }
}

@-moz-keyframes tada {
  0% {
    -moz-transform: scale(1);
  }

  10%, 20% {
    -moz-transform: scale(0.9) rotate(-3deg);
  }

  30%, 50%, 70%, 90% {
    -moz-transform: scale(1.1) rotate(3deg);
  }

  40%, 60%, 80% {
    -moz-transform: scale(1.1) rotate(-3deg);
  }

  100% {
    -moz-transform: scale(1) rotate(0);
  }
}

@-o-keyframes tada {
  0% {
    -o-transform: scale(1);
  }

  10%, 20% {
    -o-transform: scale(0.9) rotate(-3deg);
  }

  30%, 50%, 70%, 90% {
    -o-transform: scale(1.1) rotate(3deg);
  }

  40%, 60%, 80% {
    -o-transform: scale(1.1) rotate(-3deg);
  }

  100% {
    -o-transform: scale(1) rotate(0);
  }
}

@keyframes tada {
  0% {
    transform: scale(1);
  }

  10%, 20% {
    transform: scale(0.9) rotate(-3deg);
  }

  30%, 50%, 70%, 90% {
    transform: scale(1.1) rotate(3deg);
  }

  40%, 60%, 80% {
    transform: scale(1.1) rotate(-3deg);
  }

  100% {
    transform: scale(1) rotate(0);
  }
}

.tada {
  -webkit-animation-name: tada;
  -moz-animation-name: tada;
  -o-animation-name: tada;
  animation-name: tada;
}

@-webkit-keyframes swing {
  20%, 40%, 60%, 80%, 100% {
    -webkit-transform-origin: top center;
  }

  20% {
    -webkit-transform: rotate(15deg);
  }

  40% {
    -webkit-transform: rotate(-10deg);
  }

  60% {
    -webkit-transform: rotate(5deg);
  }

  80% {
    -webkit-transform: rotate(-5deg);
  }

  100% {
    -webkit-transform: rotate(0deg);
  }
}

@-moz-keyframes swing {
  20% {
    -moz-transform: rotate(15deg);
  }

  40% {
    -moz-transform: rotate(-10deg);
  }

  60% {
    -moz-transform: rotate(5deg);
  }

  80% {
    -moz-transform: rotate(-5deg);
  }

  100% {
    -moz-transform: rotate(0deg);
  }
}

@-o-keyframes swing {
  20% {
    -o-transform: rotate(15deg);
  }

  40% {
    -o-transform: rotate(-10deg);
  }

  60% {
    -o-transform: rotate(5deg);
  }

  80% {
    -o-transform: rotate(-5deg);
  }

  100% {
    -o-transform: rotate(0deg);
  }
}

@keyframes swing {
  20% {
    transform: rotate(15deg);
  }

  40% {
    transform: rotate(-10deg);
  }

  60% {
    transform: rotate(5deg);
  }

  80% {
    transform: rotate(-5deg);
  }

  100% {
    transform: rotate(0deg);
  }
}

.swing {
  -webkit-transform-origin: top center;
  -moz-transform-origin: top center;
  -o-transform-origin: top center;
  transform-origin: top center;
  -webkit-animation-name: swing;
  -moz-animation-name: swing;
  -o-animation-name: swing;
  animation-name: swing;
}

/* originally authored by Nick Pettit - https://github.com/nickpettit/glide */

@-webkit-keyframes wobble {
  0% {
    -webkit-transform: translateX(0%);
  }

  15% {
    -webkit-transform: translateX(-25%) rotate(-5deg);
  }

  30% {
    -webkit-transform: translateX(20%) rotate(3deg);
  }

  45% {
    -webkit-transform: translateX(-15%) rotate(-3deg);
  }

  60% {
    -webkit-transform: translateX(10%) rotate(2deg);
  }

  75% {
    -webkit-transform: translateX(-5%) rotate(-1deg);
  }

  100% {
    -webkit-transform: translateX(0%);
  }
}

@-moz-keyframes wobble {
  0% {
    -moz-transform: translateX(0%);
  }

  15% {
    -moz-transform: translateX(-25%) rotate(-5deg);
  }

  30% {
    -moz-transform: translateX(20%) rotate(3deg);
  }

  45% {
    -moz-transform: translateX(-15%) rotate(-3deg);
  }

  60% {
    -moz-transform: translateX(10%) rotate(2deg);
  }

  75% {
    -moz-transform: translateX(-5%) rotate(-1deg);
  }

  100% {
    -moz-transform: translateX(0%);
  }
}

@-o-keyframes wobble {
  0% {
    -o-transform: translateX(0%);
  }

  15% {
    -o-transform: translateX(-25%) rotate(-5deg);
  }

  30% {
    -o-transform: translateX(20%) rotate(3deg);
  }

  45% {
    -o-transform: translateX(-15%) rotate(-3deg);
  }

  60% {
    -o-transform: translateX(10%) rotate(2deg);
  }

  75% {
    -o-transform: translateX(-5%) rotate(-1deg);
  }

  100% {
    -o-transform: translateX(0%);
  }
}

@keyframes wobble {
  0% {
    transform: translateX(0%);
  }

  15% {
    transform: translateX(-25%) rotate(-5deg);
  }

  30% {
    transform: translateX(20%) rotate(3deg);
  }

  45% {
    transform: translateX(-15%) rotate(-3deg);
  }

  60% {
    transform: translateX(10%) rotate(2deg);
  }

  75% {
    transform: translateX(-5%) rotate(-1deg);
  }

  100% {
    transform: translateX(0%);
  }
}

.wobble {
  -webkit-animation-name: wobble;
  -moz-animation-name: wobble;
  -o-animation-name: wobble;
  animation-name: wobble;
}

/* originally authored by Nick Pettit - https://github.com/nickpettit/glide */

@-webkit-keyframes pulse {
  0% {
    -webkit-transform: scale(1);
  }

  50% {
    -webkit-transform: scale(1.1);
  }

  100% {
    -webkit-transform: scale(1);
  }
}

@-moz-keyframes pulse {
  0% {
    -moz-transform: scale(1);
  }

  50% {
    -moz-transform: scale(1.1);
  }

  100% {
    -moz-transform: scale(1);
  }
}

@-o-keyframes pulse {
  0% {
    -o-transform: scale(1);
  }

  50% {
    -o-transform: scale(1.1);
  }

  100% {
    -o-transform: scale(1);
  }
}

@keyframes pulse {
  0% {
    transform: scale(1);
  }

  50% {
    transform: scale(1.1);
  }

  100% {
    transform: scale(1);
  }
}

.pulse {
  -webkit-animation-name: pulse;
  -moz-animation-name: pulse;
  -o-animation-name: pulse;
  animation-name: pulse;
}

@-webkit-keyframes flip {
  0% {
    -webkit-transform: perspective(400px) translateZ(0) rotateY(0) scale(1);
    -webkit-animation-timing-function: ease-out;
  }

  40% {
    -webkit-transform: perspective(400px) translateZ(150px) rotateY(170deg) scale(1);
    -webkit-animation-timing-function: ease-out;
  }

  50% {
    -webkit-transform: perspective(400px) translateZ(150px) rotateY(190deg) scale(1);
    -webkit-animation-timing-function: ease-in;
  }

  80% {
    -webkit-transform: perspective(400px) translateZ(0) rotateY(360deg) scale(0.95);
    -webkit-animation-timing-function: ease-in;
  }

  100% {
    -webkit-transform: perspective(400px) translateZ(0) rotateY(360deg) scale(1);
    -webkit-animation-timing-function: ease-in;
  }
}

@-moz-keyframes flip {
  0% {
    -moz-transform: perspective(400px) translateZ(0) rotateY(0) scale(1);
    -moz-animation-timing-function: ease-out;
  }

  40% {
    -moz-transform: perspective(400px) translateZ(150px) rotateY(170deg) scale(1);
    -moz-animation-timing-function: ease-out;
  }

  50% {
    -moz-transform: perspective(400px) translateZ(150px) rotateY(190deg) scale(1);
    -moz-animation-timing-function: ease-in;
  }

  80% {
    -moz-transform: perspective(400px) translateZ(0) rotateY(360deg) scale(0.95);
    -moz-animation-timing-function: ease-in;
  }

  100% {
    -moz-transform: perspective(400px) translateZ(0) rotateY(360deg) scale(1);
    -moz-animation-timing-function: ease-in;
  }
}

@-o-keyframes flip {
  0% {
    -o-transform: perspective(400px) translateZ(0) rotateY(0) scale(1);
    -o-animation-timing-function: ease-out;
  }

  40% {
    -o-transform: perspective(400px) translateZ(150px) rotateY(170deg) scale(1);
    -o-animation-timing-function: ease-out;
  }

  50% {
    -o-transform: perspective(400px) translateZ(150px) rotateY(190deg) scale(1);
    -o-animation-timing-function: ease-in;
  }

  80% {
    -o-transform: perspective(400px) translateZ(0) rotateY(360deg) scale(0.95);
    -o-animation-timing-function: ease-in;
  }

  100% {
    -o-transform: perspective(400px) translateZ(0) rotateY(360deg) scale(1);
    -o-animation-timing-function: ease-in;
  }
}

@keyframes flip {
  0% {
    transform: perspective(400px) translateZ(0) rotateY(0) scale(1);
    animation-timing-function: ease-out;
  }

  40% {
    transform: perspective(400px) translateZ(150px) rotateY(170deg) scale(1);
    animation-timing-function: ease-out;
  }

  50% {
    transform: perspective(400px) translateZ(150px) rotateY(190deg) scale(1);
    animation-timing-function: ease-in;
  }

  80% {
    transform: perspective(400px) translateZ(0) rotateY(360deg) scale(0.95);
    animation-timing-function: ease-in;
  }

  100% {
    transform: perspective(400px) translateZ(0) rotateY(360deg) scale(1);
    animation-timing-function: ease-in;
  }
}

.animated.flip {
  -webkit-backface-visibility: visible !important;
  -webkit-animation-name: flip;
  -moz-backface-visibility: visible !important;
  -moz-animation-name: flip;
  -o-backface-visibility: visible !important;
  -o-animation-name: flip;
  backface-visibility: visible !important;
  animation-name: flip;
}

@-webkit-keyframes flipInX {
  0% {
    -webkit-transform: perspective(400px) rotateX(90deg);
    opacity: 0;
  }

  40% {
    -webkit-transform: perspective(400px) rotateX(-10deg);
  }

  70% {
    -webkit-transform: perspective(400px) rotateX(10deg);
  }

  100% {
    -webkit-transform: perspective(400px) rotateX(0deg);
    opacity: 1;
  }
}

@-moz-keyframes flipInX {
  0% {
    -moz-transform: perspective(400px) rotateX(90deg);
    opacity: 0;
  }

  40% {
    -moz-transform: perspective(400px) rotateX(-10deg);
  }

  70% {
    -moz-transform: perspective(400px) rotateX(10deg);
  }

  100% {
    -moz-transform: perspective(400px) rotateX(0deg);
    opacity: 1;
  }
}

@-o-keyframes flipInX {
  0% {
    -o-transform: perspective(400px) rotateX(90deg);
    opacity: 0;
  }

  40% {
    -o-transform: perspective(400px) rotateX(-10deg);
  }

  70% {
    -o-transform: perspective(400px) rotateX(10deg);
  }

  100% {
    -o-transform: perspective(400px) rotateX(0deg);
    opacity: 1;
  }
}

@keyframes flipInX {
  0% {
    transform: perspective(400px) rotateX(90deg);
    opacity: 0;
  }

  40% {
    transform: perspective(400px) rotateX(-10deg);
  }

  70% {
    transform: perspective(400px) rotateX(10deg);
  }

  100% {
    transform: perspective(400px) rotateX(0deg);
    opacity: 1;
  }
}

.flipInX {
  -webkit-backface-visibility: visible !important;
  -webkit-animation-name: flipInX;
  -moz-backface-visibility: visible !important;
  -moz-animation-name: flipInX;
  -o-backface-visibility: visible !important;
  -o-animation-name: flipInX;
  backface-visibility: visible !important;
  animation-name: flipInX;
}

@-webkit-keyframes flipOutX {
  0% {
    -webkit-transform: perspective(400px) rotateX(0deg);
    opacity: 1;
  }

  100% {
    -webkit-transform: perspective(400px) rotateX(90deg);
    opacity: 0;
  }
}

@-moz-keyframes flipOutX {
  0% {
    -moz-transform: perspective(400px) rotateX(0deg);
    opacity: 1;
  }

  100% {
    -moz-transform: perspective(400px) rotateX(90deg);
    opacity: 0;
  }
}

@-o-keyframes flipOutX {
  0% {
    -o-transform: perspective(400px) rotateX(0deg);
    opacity: 1;
  }

  100% {
    -o-transform: perspective(400px) rotateX(90deg);
    opacity: 0;
  }
}

@keyframes flipOutX {
  0% {
    transform: perspective(400px) rotateX(0deg);
    opacity: 1;
  }

  100% {
    transform: perspective(400px) rotateX(90deg);
    opacity: 0;
  }
}

.flipOutX {
  -webkit-animation-name: flipOutX;
  -webkit-backface-visibility: visible !important;
  -moz-animation-name: flipOutX;
  -moz-backface-visibility: visible !important;
  -o-animation-name: flipOutX;
  -o-backface-visibility: visible !important;
  animation-name: flipOutX;
  backface-visibility: visible !important;
}

@-webkit-keyframes flipInY {
  0% {
    -webkit-transform: perspective(400px) rotateY(90deg);
    opacity: 0;
  }

  40% {
    -webkit-transform: perspective(400px) rotateY(-10deg);
  }

  70% {
    -webkit-transform: perspective(400px) rotateY(10deg);
  }

  100% {
    -webkit-transform: perspective(400px) rotateY(0deg);
    opacity: 1;
  }
}

@-moz-keyframes flipInY {
  0% {
    -moz-transform: perspective(400px) rotateY(90deg);
    opacity: 0;
  }

  40% {
    -moz-transform: perspective(400px) rotateY(-10deg);
  }

  70% {
    -moz-transform: perspective(400px) rotateY(10deg);
  }

  100% {
    -moz-transform: perspective(400px) rotateY(0deg);
    opacity: 1;
  }
}

@-o-keyframes flipInY {
  0% {
    -o-transform: perspective(400px) rotateY(90deg);
    opacity: 0;
  }

  40% {
    -o-transform: perspective(400px) rotateY(-10deg);
  }

  70% {
    -o-transform: perspective(400px) rotateY(10deg);
  }

  100% {
    -o-transform: perspective(400px) rotateY(0deg);
    opacity: 1;
  }
}

@keyframes flipInY {
  0% {
    transform: perspective(400px) rotateY(90deg);
    opacity: 0;
  }

  40% {
    transform: perspective(400px) rotateY(-10deg);
  }

  70% {
    transform: perspective(400px) rotateY(10deg);
  }

  100% {
    transform: perspective(400px) rotateY(0deg);
    opacity: 1;
  }
}

.flipInY {
  -webkit-backface-visibility: visible !important;
  -webkit-animation-name: flipInY;
  -moz-backface-visibility: visible !important;
  -moz-animation-name: flipInY;
  -o-backface-visibility: visible !important;
  -o-animation-name: flipInY;
  backface-visibility: visible !important;
  animation-name: flipInY;
}

@-webkit-keyframes flipOutY {
  0% {
    -webkit-transform: perspective(400px) rotateY(0deg);
    opacity: 1;
  }

  100% {
    -webkit-transform: perspective(400px) rotateY(90deg);
    opacity: 0;
  }
}

@-moz-keyframes flipOutY {
  0% {
    -moz-transform: perspective(400px) rotateY(0deg);
    opacity: 1;
  }

  100% {
    -moz-transform: perspective(400px) rotateY(90deg);
    opacity: 0;
  }
}

@-o-keyframes flipOutY {
  0% {
    -o-transform: perspective(400px) rotateY(0deg);
    opacity: 1;
  }

  100% {
    -o-transform: perspective(400px) rotateY(90deg);
    opacity: 0;
  }
}

@keyframes flipOutY {
  0% {
    transform: perspective(400px) rotateY(0deg);
    opacity: 1;
  }

  100% {
    transform: perspective(400px) rotateY(90deg);
    opacity: 0;
  }
}

.flipOutY {
  -webkit-backface-visibility: visible !important;
  -webkit-animation-name: flipOutY;
  -moz-backface-visibility: visible !important;
  -moz-animation-name: flipOutY;
  -o-backface-visibility: visible !important;
  -o-animation-name: flipOutY;
  backface-visibility: visible !important;
  animation-name: flipOutY;
}

@-webkit-keyframes fadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@-moz-keyframes fadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@-o-keyframes fadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

.fadeIn {
  -webkit-animation-name: fadeIn;
  -moz-animation-name: fadeIn;
  -o-animation-name: fadeIn;
  animation-name: fadeIn;
}

@-webkit-keyframes fadeInUp {
  0% {
    opacity: 0;
    -webkit-transform: translateY(20px);
  }

  100% {
    opacity: 1;
    -webkit-transform: translateY(0);
  }
}

@-moz-keyframes fadeInUp {
  0% {
    opacity: 0;
    -moz-transform: translateY(20px);
  }

  100% {
    opacity: 1;
    -moz-transform: translateY(0);
  }
}

@-o-keyframes fadeInUp {
  0% {
    opacity: 0;
    -o-transform: translateY(20px);
  }

  100% {
    opacity: 1;
    -o-transform: translateY(0);
  }
}

@keyframes fadeInUp {
  0% {
    opacity: 0;
    transform: translateY(20px);
  }

  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

.fadeInUp {
  -webkit-animation-name: fadeInUp;
  -moz-animation-name: fadeInUp;
  -o-animation-name: fadeInUp;
  animation-name: fadeInUp;
}

@-webkit-keyframes fadeInDown {
  0% {
    opacity: 0;
    -webkit-transform: translateY(-20px);
  }

  100% {
    opacity: 1;
    -webkit-transform: translateY(0);
  }
}

@-moz-keyframes fadeInDown {
  0% {
    opacity: 0;
    -moz-transform: translateY(-20px);
  }

  100% {
    opacity: 1;
    -moz-transform: translateY(0);
  }
}

@-o-keyframes fadeInDown {
  0% {
    opacity: 0;
    -o-transform: translateY(-20px);
  }

  100% {
    opacity: 1;
    -o-transform: translateY(0);
  }
}

@keyframes fadeInDown {
  0% {
    opacity: 0;
    transform: translateY(-20px);
  }

  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

.fadeInDown {
  -webkit-animation-name: fadeInDown;
  -moz-animation-name: fadeInDown;
  -o-animation-name: fadeInDown;
  animation-name: fadeInDown;
}

@-webkit-keyframes fadeInLeft {
  0% {
    opacity: 0;
    -webkit-transform: translateX(-20px);
  }

  100% {
    opacity: 1;
    -webkit-transform: translateX(0);
  }
}

@-moz-keyframes fadeInLeft {
  0% {
    opacity: 0;
    -moz-transform: translateX(-20px);
  }

  100% {
    opacity: 1;
    -moz-transform: translateX(0);
  }
}

@-o-keyframes fadeInLeft {
  0% {
    opacity: 0;
    -o-transform: translateX(-20px);
  }

  100% {
    opacity: 1;
    -o-transform: translateX(0);
  }
}

@keyframes fadeInLeft {
  0% {
    opacity: 0;
    transform: translateX(-20px);
  }

  100% {
    opacity: 1;
    transform: translateX(0);
  }
}

.fadeInLeft {
  -webkit-animation-name: fadeInLeft;
  -moz-animation-name: fadeInLeft;
  -o-animation-name: fadeInLeft;
  animation-name: fadeInLeft;
}

@-webkit-keyframes fadeInRight {
  0% {
    opacity: 0;
    -webkit-transform: translateX(20px);
  }

  100% {
    opacity: 1;
    -webkit-transform: translateX(0);
  }
}

@-moz-keyframes fadeInRight {
  0% {
    opacity: 0;
    -moz-transform: translateX(20px);
  }

  100% {
    opacity: 1;
    -moz-transform: translateX(0);
  }
}

@-o-keyframes fadeInRight {
  0% {
    opacity: 0;
    -o-transform: translateX(20px);
  }

  100% {
    opacity: 1;
    -o-transform: translateX(0);
  }
}

@keyframes fadeInRight {
  0% {
    opacity: 0;
    transform: translateX(20px);
  }

  100% {
    opacity: 1;
    transform: translateX(0);
  }
}

.fadeInRight {
  -webkit-animation-name: fadeInRight;
  -moz-animation-name: fadeInRight;
  -o-animation-name: fadeInRight;
  animation-name: fadeInRight;
}

@-webkit-keyframes fadeInUpBig {
  0% {
    opacity: 0;
    -webkit-transform: translateY(2000px);
  }

  100% {
    opacity: 1;
    -webkit-transform: translateY(0);
  }
}

@-moz-keyframes fadeInUpBig {
  0% {
    opacity: 0;
    -moz-transform: translateY(2000px);
  }

  100% {
    opacity: 1;
    -moz-transform: translateY(0);
  }
}

@-o-keyframes fadeInUpBig {
  0% {
    opacity: 0;
    -o-transform: translateY(2000px);
  }

  100% {
    opacity: 1;
    -o-transform: translateY(0);
  }
}

@keyframes fadeInUpBig {
  0% {
    opacity: 0;
    transform: translateY(2000px);
  }

  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

.fadeInUpBig {
  -webkit-animation-name: fadeInUpBig;
  -moz-animation-name: fadeInUpBig;
  -o-animation-name: fadeInUpBig;
  animation-name: fadeInUpBig;
}

@-webkit-keyframes fadeInDownBig {
  0% {
    opacity: 0;
    -webkit-transform: translateY(-2000px);
  }

  100% {
    opacity: 1;
    -webkit-transform: translateY(0);
  }
}

@-moz-keyframes fadeInDownBig {
  0% {
    opacity: 0;
    -moz-transform: translateY(-2000px);
  }

  100% {
    opacity: 1;
    -moz-transform: translateY(0);
  }
}

@-o-keyframes fadeInDownBig {
  0% {
    opacity: 0;
    -o-transform: translateY(-2000px);
  }

  100% {
    opacity: 1;
    -o-transform: translateY(0);
  }
}

@keyframes fadeInDownBig {
  0% {
    opacity: 0;
    transform: translateY(-2000px);
  }

  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

.fadeInDownBig {
  -webkit-animation-name: fadeInDownBig;
  -moz-animation-name: fadeInDownBig;
  -o-animation-name: fadeInDownBig;
  animation-name: fadeInDownBig;
}

@-webkit-keyframes fadeInLeftBig {
  0% {
    opacity: 0;
    -webkit-transform: translateX(-2000px);
  }

  100% {
    opacity: 1;
    -webkit-transform: translateX(0);
  }
}

@-moz-keyframes fadeInLeftBig {
  0% {
    opacity: 0;
    -moz-transform: translateX(-2000px);
  }

  100% {
    opacity: 1;
    -moz-transform: translateX(0);
  }
}

@-o-keyframes fadeInLeftBig {
  0% {
    opacity: 0;
    -o-transform: translateX(-2000px);
  }

  100% {
    opacity: 1;
    -o-transform: translateX(0);
  }
}

@keyframes fadeInLeftBig {
  0% {
    opacity: 0;
    transform: translateX(-2000px);
  }

  100% {
    opacity: 1;
    transform: translateX(0);
  }
}

.fadeInLeftBig {
  -webkit-animation-name: fadeInLeftBig;
  -moz-animation-name: fadeInLeftBig;
  -o-animation-name: fadeInLeftBig;
  animation-name: fadeInLeftBig;
}

@-webkit-keyframes fadeInRightBig {
  0% {
    opacity: 0;
    -webkit-transform: translateX(2000px);
  }

  100% {
    opacity: 1;
    -webkit-transform: translateX(0);
  }
}

@-moz-keyframes fadeInRightBig {
  0% {
    opacity: 0;
    -moz-transform: translateX(2000px);
  }

  100% {
    opacity: 1;
    -moz-transform: translateX(0);
  }
}

@-o-keyframes fadeInRightBig {
  0% {
    opacity: 0;
    -o-transform: translateX(2000px);
  }

  100% {
    opacity: 1;
    -o-transform: translateX(0);
  }
}

@keyframes fadeInRightBig {
  0% {
    opacity: 0;
    transform: translateX(2000px);
  }

  100% {
    opacity: 1;
    transform: translateX(0);
  }
}

.fadeInRightBig {
  -webkit-animation-name: fadeInRightBig;
  -moz-animation-name: fadeInRightBig;
  -o-animation-name: fadeInRightBig;
  animation-name: fadeInRightBig;
}

@-webkit-keyframes fadeOut {
  0% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}

@-moz-keyframes fadeOut {
  0% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}

@-o-keyframes fadeOut {
  0% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}

@keyframes fadeOut {
  0% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}

.fadeOut {
  -webkit-animation-name: fadeOut;
  -moz-animation-name: fadeOut;
  -o-animation-name: fadeOut;
  animation-name: fadeOut;
}

@-webkit-keyframes fadeOutUp {
  0% {
    opacity: 1;
    -webkit-transform: translateY(0);
  }

  100% {
    opacity: 0;
    -webkit-transform: translateY(-20px);
  }
}

@-moz-keyframes fadeOutUp {
  0% {
    opacity: 1;
    -moz-transform: translateY(0);
  }

  100% {
    opacity: 0;
    -moz-transform: translateY(-20px);
  }
}

@-o-keyframes fadeOutUp {
  0% {
    opacity: 1;
    -o-transform: translateY(0);
  }

  100% {
    opacity: 0;
    -o-transform: translateY(-20px);
  }
}

@keyframes fadeOutUp {
  0% {
    opacity: 1;
    transform: translateY(0);
  }

  100% {
    opacity: 0;
    transform: translateY(-20px);
  }
}

.fadeOutUp {
  -webkit-animation-name: fadeOutUp;
  -moz-animation-name: fadeOutUp;
  -o-animation-name: fadeOutUp;
  animation-name: fadeOutUp;
}

@-webkit-keyframes fadeOutDown {
  0% {
    opacity: 1;
    -webkit-transform: translateY(0);
  }

  100% {
    opacity: 0;
    -webkit-transform: translateY(20px);
  }
}

@-moz-keyframes fadeOutDown {
  0% {
    opacity: 1;
    -moz-transform: translateY(0);
  }

  100% {
    opacity: 0;
    -moz-transform: translateY(20px);
  }
}

@-o-keyframes fadeOutDown {
  0% {
    opacity: 1;
    -o-transform: translateY(0);
  }

  100% {
    opacity: 0;
    -o-transform: translateY(20px);
  }
}

@keyframes fadeOutDown {
  0% {
    opacity: 1;
    transform: translateY(0);
  }

  100% {
    opacity: 0;
    transform: translateY(20px);
  }
}

.fadeOutDown {
  -webkit-animation-name: fadeOutDown;
  -moz-animation-name: fadeOutDown;
  -o-animation-name: fadeOutDown;
  animation-name: fadeOutDown;
}

@-webkit-keyframes fadeOutLeft {
  0% {
    opacity: 1;
    -webkit-transform: translateX(0);
  }

  100% {
    opacity: 0;
    -webkit-transform: translateX(-20px);
  }
}

@-moz-keyframes fadeOutLeft {
  0% {
    opacity: 1;
    -moz-transform: translateX(0);
  }

  100% {
    opacity: 0;
    -moz-transform: translateX(-20px);
  }
}

@-o-keyframes fadeOutLeft {
  0% {
    opacity: 1;
    -o-transform: translateX(0);
  }

  100% {
    opacity: 0;
    -o-transform: translateX(-20px);
  }
}

@keyframes fadeOutLeft {
  0% {
    opacity: 1;
    transform: translateX(0);
  }

  100% {
    opacity: 0;
    transform: translateX(-20px);
  }
}

.fadeOutLeft {
  -webkit-animation-name: fadeOutLeft;
  -moz-animation-name: fadeOutLeft;
  -o-animation-name: fadeOutLeft;
  animation-name: fadeOutLeft;
}

@-webkit-keyframes fadeOutRight {
  0% {
    opacity: 1;
    -webkit-transform: translateX(0);
  }

  100% {
    opacity: 0;
    -webkit-transform: translateX(20px);
  }
}

@-moz-keyframes fadeOutRight {
  0% {
    opacity: 1;
    -moz-transform: translateX(0);
  }

  100% {
    opacity: 0;
    -moz-transform: translateX(20px);
  }
}

@-o-keyframes fadeOutRight {
  0% {
    opacity: 1;
    -o-transform: translateX(0);
  }

  100% {
    opacity: 0;
    -o-transform: translateX(20px);
  }
}

@keyframes fadeOutRight {
  0% {
    opacity: 1;
    transform: translateX(0);
  }

  100% {
    opacity: 0;
    transform: translateX(20px);
  }
}

.fadeOutRight {
  -webkit-animation-name: fadeOutRight;
  -moz-animation-name: fadeOutRight;
  -o-animation-name: fadeOutRight;
  animation-name: fadeOutRight;
}

@-webkit-keyframes fadeOutUpBig {
  0% {
    opacity: 1;
    -webkit-transform: translateY(0);
  }

  100% {
    opacity: 0;
    -webkit-transform: translateY(-2000px);
  }
}

@-moz-keyframes fadeOutUpBig {
  0% {
    opacity: 1;
    -moz-transform: translateY(0);
  }

  100% {
    opacity: 0;
    -moz-transform: translateY(-2000px);
  }
}

@-o-keyframes fadeOutUpBig {
  0% {
    opacity: 1;
    -o-transform: translateY(0);
  }

  100% {
    opacity: 0;
    -o-transform: translateY(-2000px);
  }
}

@keyframes fadeOutUpBig {
  0% {
    opacity: 1;
    transform: translateY(0);
  }

  100% {
    opacity: 0;
    transform: translateY(-2000px);
  }
}

.fadeOutUpBig {
  -webkit-animation-name: fadeOutUpBig;
  -moz-animation-name: fadeOutUpBig;
  -o-animation-name: fadeOutUpBig;
  animation-name: fadeOutUpBig;
}

@-webkit-keyframes fadeOutDownBig {
  0% {
    opacity: 1;
    -webkit-transform: translateY(0);
  }

  100% {
    opacity: 0;
    -webkit-transform: translateY(2000px);
  }
}

@-moz-keyframes fadeOutDownBig {
  0% {
    opacity: 1;
    -moz-transform: translateY(0);
  }

  100% {
    opacity: 0;
    -moz-transform: translateY(2000px);
  }
}

@-o-keyframes fadeOutDownBig {
  0% {
    opacity: 1;
    -o-transform: translateY(0);
  }

  100% {
    opacity: 0;
    -o-transform: translateY(2000px);
  }
}

@keyframes fadeOutDownBig {
  0% {
    opacity: 1;
    transform: translateY(0);
  }

  100% {
    opacity: 0;
    transform: translateY(2000px);
  }
}

.fadeOutDownBig {
  -webkit-animation-name: fadeOutDownBig;
  -moz-animation-name: fadeOutDownBig;
  -o-animation-name: fadeOutDownBig;
  animation-name: fadeOutDownBig;
}

@-webkit-keyframes fadeOutLeftBig {
  0% {
    opacity: 1;
    -webkit-transform: translateX(0);
  }

  100% {
    opacity: 0;
    -webkit-transform: translateX(-2000px);
  }
}

@-moz-keyframes fadeOutLeftBig {
  0% {
    opacity: 1;
    -moz-transform: translateX(0);
  }

  100% {
    opacity: 0;
    -moz-transform: translateX(-2000px);
  }
}

@-o-keyframes fadeOutLeftBig {
  0% {
    opacity: 1;
    -o-transform: translateX(0);
  }

  100% {
    opacity: 0;
    -o-transform: translateX(-2000px);
  }
}

@keyframes fadeOutLeftBig {
  0% {
    opacity: 1;
    transform: translateX(0);
  }

  100% {
    opacity: 0;
    transform: translateX(-2000px);
  }
}

.fadeOutLeftBig {
  -webkit-animation-name: fadeOutLeftBig;
  -moz-animation-name: fadeOutLeftBig;
  -o-animation-name: fadeOutLeftBig;
  animation-name: fadeOutLeftBig;
}

@-webkit-keyframes fadeOutRightBig {
  0% {
    opacity: 1;
    -webkit-transform: translateX(0);
  }

  100% {
    opacity: 0;
    -webkit-transform: translateX(2000px);
  }
}

@-moz-keyframes fadeOutRightBig {
  0% {
    opacity: 1;
    -moz-transform: translateX(0);
  }

  100% {
    opacity: 0;
    -moz-transform: translateX(2000px);
  }
}

@-o-keyframes fadeOutRightBig {
  0% {
    opacity: 1;
    -o-transform: translateX(0);
  }

  100% {
    opacity: 0;
    -o-transform: translateX(2000px);
  }
}

@keyframes fadeOutRightBig {
  0% {
    opacity: 1;
    transform: translateX(0);
  }

  100% {
    opacity: 0;
    transform: translateX(2000px);
  }
}

.fadeOutRightBig {
  -webkit-animation-name: fadeOutRightBig;
  -moz-animation-name: fadeOutRightBig;
  -o-animation-name: fadeOutRightBig;
  animation-name: fadeOutRightBig;
}

@-webkit-keyframes slideInDown {
  0% {
    opacity: 0;
    -webkit-transform: translateY(-2000px);
  }

  100% {
    -webkit-transform: translateY(0);
  }
}

@-moz-keyframes slideInDown {
  0% {
    opacity: 0;
    -moz-transform: translateY(-2000px);
  }

  100% {
    -moz-transform: translateY(0);
  }
}

@-o-keyframes slideInDown {
  0% {
    opacity: 0;
    -o-transform: translateY(-2000px);
  }

  100% {
    -o-transform: translateY(0);
  }
}

@keyframes slideInDown {
  0% {
    opacity: 0;
    transform: translateY(-2000px);
  }

  100% {
    transform: translateY(0);
  }
}

.slideInDown {
  -webkit-animation-name: slideInDown;
  -moz-animation-name: slideInDown;
  -o-animation-name: slideInDown;
  animation-name: slideInDown;
}

@-webkit-keyframes slideInLeft {
  0% {
    opacity: 0;
    -webkit-transform: translateX(-2000px);
  }

  100% {
    -webkit-transform: translateX(0);
  }
}

@-moz-keyframes slideInLeft {
  0% {
    opacity: 0;
    -moz-transform: translateX(-2000px);
  }

  100% {
    -moz-transform: translateX(0);
  }
}

@-o-keyframes slideInLeft {
  0% {
    opacity: 0;
    -o-transform: translateX(-2000px);
  }

  100% {
    -o-transform: translateX(0);
  }
}

@keyframes slideInLeft {
  0% {
    opacity: 0;
    transform: translateX(-2000px);
  }

  100% {
    transform: translateX(0);
  }
}

.slideInLeft {
  -webkit-animation-name: slideInLeft;
  -moz-animation-name: slideInLeft;
  -o-animation-name: slideInLeft;
  animation-name: slideInLeft;
}

@-webkit-keyframes slideInRight {
  0% {
    opacity: 0;
    -webkit-transform: translateX(2000px);
  }

  100% {
    -webkit-transform: translateX(0);
  }
}

@-moz-keyframes slideInRight {
  0% {
    opacity: 0;
    -moz-transform: translateX(2000px);
  }

  100% {
    -moz-transform: translateX(0);
  }
}

@-o-keyframes slideInRight {
  0% {
    opacity: 0;
    -o-transform: translateX(2000px);
  }

  100% {
    -o-transform: translateX(0);
  }
}

@keyframes slideInRight {
  0% {
    opacity: 0;
    transform: translateX(2000px);
  }

  100% {
    transform: translateX(0);
  }
}

.slideInRight {
  -webkit-animation-name: slideInRight;
  -moz-animation-name: slideInRight;
  -o-animation-name: slideInRight;
  animation-name: slideInRight;
}

@-webkit-keyframes slideOutUp {
  0% {
    -webkit-transform: translateY(0);
  }

  100% {
    opacity: 0;
    -webkit-transform: translateY(-2000px);
  }
}

@-moz-keyframes slideOutUp {
  0% {
    -moz-transform: translateY(0);
  }

  100% {
    opacity: 0;
    -moz-transform: translateY(-2000px);
  }
}

@-o-keyframes slideOutUp {
  0% {
    -o-transform: translateY(0);
  }

  100% {
    opacity: 0;
    -o-transform: translateY(-2000px);
  }
}

@keyframes slideOutUp {
  0% {
    transform: translateY(0);
  }

  100% {
    opacity: 0;
    transform: translateY(-2000px);
  }
}

.slideOutUp {
  -webkit-animation-name: slideOutUp;
  -moz-animation-name: slideOutUp;
  -o-animation-name: slideOutUp;
  animation-name: slideOutUp;
}

@-webkit-keyframes slideOutLeft {
  0% {
    -webkit-transform: translateX(0);
  }

  100% {
    opacity: 0;
    -webkit-transform: translateX(-2000px);
  }
}

@-moz-keyframes slideOutLeft {
  0% {
    -moz-transform: translateX(0);
  }

  100% {
    opacity: 0;
    -moz-transform: translateX(-2000px);
  }
}

@-o-keyframes slideOutLeft {
  0% {
    -o-transform: translateX(0);
  }

  100% {
    opacity: 0;
    -o-transform: translateX(-2000px);
  }
}

@keyframes slideOutLeft {
  0% {
    transform: translateX(0);
  }

  100% {
    opacity: 0;
    transform: translateX(-2000px);
  }
}

.slideOutLeft {
  -webkit-animation-name: slideOutLeft;
  -moz-animation-name: slideOutLeft;
  -o-animation-name: slideOutLeft;
  animation-name: slideOutLeft;
}

@-webkit-keyframes slideOutRight {
  0% {
    -webkit-transform: translateX(0);
  }

  100% {
    opacity: 0;
    -webkit-transform: translateX(2000px);
  }
}

@-moz-keyframes slideOutRight {
  0% {
    -moz-transform: translateX(0);
  }

  100% {
    opacity: 0;
    -moz-transform: translateX(2000px);
  }
}

@-o-keyframes slideOutRight {
  0% {
    -o-transform: translateX(0);
  }

  100% {
    opacity: 0;
    -o-transform: translateX(2000px);
  }
}

@keyframes slideOutRight {
  0% {
    transform: translateX(0);
  }

  100% {
    opacity: 0;
    transform: translateX(2000px);
  }
}

.slideOutRight {
  -webkit-animation-name: slideOutRight;
  -moz-animation-name: slideOutRight;
  -o-animation-name: slideOutRight;
  animation-name: slideOutRight;
}

@-webkit-keyframes bounceIn {
  0% {
    opacity: 0;
    -webkit-transform: scale(0.3);
  }

  50% {
    opacity: 1;
    -webkit-transform: scale(1.05);
  }

  70% {
    -webkit-transform: scale(0.9);
  }

  100% {
    -webkit-transform: scale(1);
  }
}

@-moz-keyframes bounceIn {
  0% {
    opacity: 0;
    -moz-transform: scale(0.3);
  }

  50% {
    opacity: 1;
    -moz-transform: scale(1.05);
  }

  70% {
    -moz-transform: scale(0.9);
  }

  100% {
    -moz-transform: scale(1);
  }
}

@-o-keyframes bounceIn {
  0% {
    opacity: 0;
    -o-transform: scale(0.3);
  }

  50% {
    opacity: 1;
    -o-transform: scale(1.05);
  }

  70% {
    -o-transform: scale(0.9);
  }

  100% {
    -o-transform: scale(1);
  }
}

@keyframes bounceIn {
  0% {
    opacity: 0;
    transform: scale(0.3);
  }

  50% {
    opacity: 1;
    transform: scale(1.05);
  }

  70% {
    transform: scale(0.9);
  }

  100% {
    transform: scale(1);
  }
}

.bounceIn {
  -webkit-animation-name: bounceIn;
  -moz-animation-name: bounceIn;
  -o-animation-name: bounceIn;
  animation-name: bounceIn;
}

@-webkit-keyframes bounceInUp {
  0% {
    opacity: 0;
    -webkit-transform: translateY(2000px);
  }

  60% {
    opacity: 1;
    -webkit-transform: translateY(-30px);
  }

  80% {
    -webkit-transform: translateY(10px);
  }

  100% {
    -webkit-transform: translateY(0);
  }
}

@-moz-keyframes bounceInUp {
  0% {
    opacity: 0;
    -moz-transform: translateY(2000px);
  }

  60% {
    opacity: 1;
    -moz-transform: translateY(-30px);
  }

  80% {
    -moz-transform: translateY(10px);
  }

  100% {
    -moz-transform: translateY(0);
  }
}

@-o-keyframes bounceInUp {
  0% {
    opacity: 0;
    -o-transform: translateY(2000px);
  }

  60% {
    opacity: 1;
    -o-transform: translateY(-30px);
  }

  80% {
    -o-transform: translateY(10px);
  }

  100% {
    -o-transform: translateY(0);
  }
}

@keyframes bounceInUp {
  0% {
    opacity: 0;
    transform: translateY(2000px);
  }

  60% {
    opacity: 1;
    transform: translateY(-30px);
  }

  80% {
    transform: translateY(10px);
  }

  100% {
    transform: translateY(0);
  }
}

.bounceInUp {
  -webkit-animation-name: bounceInUp;
  -moz-animation-name: bounceInUp;
  -o-animation-name: bounceInUp;
  animation-name: bounceInUp;
}

@-webkit-keyframes bounceInDown {
  0% {
    opacity: 0;
    -webkit-transform: translateY(-2000px);
  }

  60% {
    opacity: 1;
    -webkit-transform: translateY(30px);
  }

  80% {
    -webkit-transform: translateY(-10px);
  }

  100% {
    -webkit-transform: translateY(0);
  }
}

@-moz-keyframes bounceInDown {
  0% {
    opacity: 0;
    -moz-transform: translateY(-2000px);
  }

  60% {
    opacity: 1;
    -moz-transform: translateY(30px);
  }

  80% {
    -moz-transform: translateY(-10px);
  }

  100% {
    -moz-transform: translateY(0);
  }
}

@-o-keyframes bounceInDown {
  0% {
    opacity: 0;
    -o-transform: translateY(-2000px);
  }

  60% {
    opacity: 1;
    -o-transform: translateY(30px);
  }

  80% {
    -o-transform: translateY(-10px);
  }

  100% {
    -o-transform: translateY(0);
  }
}

@keyframes bounceInDown {
  0% {
    opacity: 0;
    transform: translateY(-2000px);
  }

  60% {
    opacity: 1;
    transform: translateY(30px);
  }

  80% {
    transform: translateY(-10px);
  }

  100% {
    transform: translateY(0);
  }
}

.bounceInDown {
  -webkit-animation-name: bounceInDown;
  -moz-animation-name: bounceInDown;
  -o-animation-name: bounceInDown;
  animation-name: bounceInDown;
}

@-webkit-keyframes bounceInLeft {
  0% {
    opacity: 0;
    -webkit-transform: translateX(-2000px);
  }

  60% {
    opacity: 1;
    -webkit-transform: translateX(30px);
  }

  80% {
    -webkit-transform: translateX(-10px);
  }

  100% {
    -webkit-transform: translateX(0);
  }
}

@-moz-keyframes bounceInLeft {
  0% {
    opacity: 0;
    -moz-transform: translateX(-2000px);
  }

  60% {
    opacity: 1;
    -moz-transform: translateX(30px);
  }

  80% {
    -moz-transform: translateX(-10px);
  }

  100% {
    -moz-transform: translateX(0);
  }
}

@-o-keyframes bounceInLeft {
  0% {
    opacity: 0;
    -o-transform: translateX(-2000px);
  }

  60% {
    opacity: 1;
    -o-transform: translateX(30px);
  }

  80% {
    -o-transform: translateX(-10px);
  }

  100% {
    -o-transform: translateX(0);
  }
}

@keyframes bounceInLeft {
  0% {
    opacity: 0;
    transform: translateX(-2000px);
  }

  60% {
    opacity: 1;
    transform: translateX(30px);
  }

  80% {
    transform: translateX(-10px);
  }

  100% {
    transform: translateX(0);
  }
}

.bounceInLeft {
  -webkit-animation-name: bounceInLeft;
  -moz-animation-name: bounceInLeft;
  -o-animation-name: bounceInLeft;
  animation-name: bounceInLeft;
}

@-webkit-keyframes bounceInRight {
  0% {
    opacity: 0;
    -webkit-transform: translateX(2000px);
  }

  60% {
    opacity: 1;
    -webkit-transform: translateX(-30px);
  }

  80% {
    -webkit-transform: translateX(10px);
  }

  100% {
    -webkit-transform: translateX(0);
  }
}

@-moz-keyframes bounceInRight {
  0% {
    opacity: 0;
    -moz-transform: translateX(2000px);
  }

  60% {
    opacity: 1;
    -moz-transform: translateX(-30px);
  }

  80% {
    -moz-transform: translateX(10px);
  }

  100% {
    -moz-transform: translateX(0);
  }
}

@-o-keyframes bounceInRight {
  0% {
    opacity: 0;
    -o-transform: translateX(2000px);
  }

  60% {
    opacity: 1;
    -o-transform: translateX(-30px);
  }

  80% {
    -o-transform: translateX(10px);
  }

  100% {
    -o-transform: translateX(0);
  }
}

@keyframes bounceInRight {
  0% {
    opacity: 0;
    transform: translateX(2000px);
  }

  60% {
    opacity: 1;
    transform: translateX(-30px);
  }

  80% {
    transform: translateX(10px);
  }

  100% {
    transform: translateX(0);
  }
}

.bounceInRight {
  -webkit-animation-name: bounceInRight;
  -moz-animation-name: bounceInRight;
  -o-animation-name: bounceInRight;
  animation-name: bounceInRight;
}

@-webkit-keyframes bounceOut {
  0% {
    -webkit-transform: scale(1);
  }

  25% {
    -webkit-transform: scale(0.95);
  }

  50% {
    opacity: 1;
    -webkit-transform: scale(1.1);
  }

  100% {
    opacity: 0;
    -webkit-transform: scale(0.3);
  }
}

@-moz-keyframes bounceOut {
  0% {
    -moz-transform: scale(1);
  }

  25% {
    -moz-transform: scale(0.95);
  }

  50% {
    opacity: 1;
    -moz-transform: scale(1.1);
  }

  100% {
    opacity: 0;
    -moz-transform: scale(0.3);
  }
}

@-o-keyframes bounceOut {
  0% {
    -o-transform: scale(1);
  }

  25% {
    -o-transform: scale(0.95);
  }

  50% {
    opacity: 1;
    -o-transform: scale(1.1);
  }

  100% {
    opacity: 0;
    -o-transform: scale(0.3);
  }
}

@keyframes bounceOut {
  0% {
    transform: scale(1);
  }

  25% {
    transform: scale(0.95);
  }

  50% {
    opacity: 1;
    transform: scale(1.1);
  }

  100% {
    opacity: 0;
    transform: scale(0.3);
  }
}

.bounceOut {
  -webkit-animation-name: bounceOut;
  -moz-animation-name: bounceOut;
  -o-animation-name: bounceOut;
  animation-name: bounceOut;
}

@-webkit-keyframes bounceOutUp {
  0% {
    -webkit-transform: translateY(0);
  }

  20% {
    opacity: 1;
    -webkit-transform: translateY(20px);
  }

  100% {
    opacity: 0;
    -webkit-transform: translateY(-2000px);
  }
}

@-moz-keyframes bounceOutUp {
  0% {
    -moz-transform: translateY(0);
  }

  20% {
    opacity: 1;
    -moz-transform: translateY(20px);
  }

  100% {
    opacity: 0;
    -moz-transform: translateY(-2000px);
  }
}

@-o-keyframes bounceOutUp {
  0% {
    -o-transform: translateY(0);
  }

  20% {
    opacity: 1;
    -o-transform: translateY(20px);
  }

  100% {
    opacity: 0;
    -o-transform: translateY(-2000px);
  }
}

@keyframes bounceOutUp {
  0% {
    transform: translateY(0);
  }

  20% {
    opacity: 1;
    transform: translateY(20px);
  }

  100% {
    opacity: 0;
    transform: translateY(-2000px);
  }
}

.bounceOutUp {
  -webkit-animation-name: bounceOutUp;
  -moz-animation-name: bounceOutUp;
  -o-animation-name: bounceOutUp;
  animation-name: bounceOutUp;
}

@-webkit-keyframes bounceOutDown {
  0% {
    -webkit-transform: translateY(0);
  }

  20% {
    opacity: 1;
    -webkit-transform: translateY(-20px);
  }

  100% {
    opacity: 0;
    -webkit-transform: translateY(2000px);
  }
}

@-moz-keyframes bounceOutDown {
  0% {
    -moz-transform: translateY(0);
  }

  20% {
    opacity: 1;
    -moz-transform: translateY(-20px);
  }

  100% {
    opacity: 0;
    -moz-transform: translateY(2000px);
  }
}

@-o-keyframes bounceOutDown {
  0% {
    -o-transform: translateY(0);
  }

  20% {
    opacity: 1;
    -o-transform: translateY(-20px);
  }

  100% {
    opacity: 0;
    -o-transform: translateY(2000px);
  }
}

@keyframes bounceOutDown {
  0% {
    transform: translateY(0);
  }

  20% {
    opacity: 1;
    transform: translateY(-20px);
  }

  100% {
    opacity: 0;
    transform: translateY(2000px);
  }
}

.bounceOutDown {
  -webkit-animation-name: bounceOutDown;
  -moz-animation-name: bounceOutDown;
  -o-animation-name: bounceOutDown;
  animation-name: bounceOutDown;
}

@-webkit-keyframes bounceOutLeft {
  0% {
    -webkit-transform: translateX(0);
  }

  20% {
    opacity: 1;
    -webkit-transform: translateX(20px);
  }

  100% {
    opacity: 0;
    -webkit-transform: translateX(-2000px);
  }
}

@-moz-keyframes bounceOutLeft {
  0% {
    -moz-transform: translateX(0);
  }

  20% {
    opacity: 1;
    -moz-transform: translateX(20px);
  }

  100% {
    opacity: 0;
    -moz-transform: translateX(-2000px);
  }
}

@-o-keyframes bounceOutLeft {
  0% {
    -o-transform: translateX(0);
  }

  20% {
    opacity: 1;
    -o-transform: translateX(20px);
  }

  100% {
    opacity: 0;
    -o-transform: translateX(-2000px);
  }
}

@keyframes bounceOutLeft {
  0% {
    transform: translateX(0);
  }

  20% {
    opacity: 1;
    transform: translateX(20px);
  }

  100% {
    opacity: 0;
    transform: translateX(-2000px);
  }
}

.bounceOutLeft {
  -webkit-animation-name: bounceOutLeft;
  -moz-animation-name: bounceOutLeft;
  -o-animation-name: bounceOutLeft;
  animation-name: bounceOutLeft;
}

@-webkit-keyframes bounceOutRight {
  0% {
    -webkit-transform: translateX(0);
  }

  20% {
    opacity: 1;
    -webkit-transform: translateX(-20px);
  }

  100% {
    opacity: 0;
    -webkit-transform: translateX(2000px);
  }
}

@-moz-keyframes bounceOutRight {
  0% {
    -moz-transform: translateX(0);
  }

  20% {
    opacity: 1;
    -moz-transform: translateX(-20px);
  }

  100% {
    opacity: 0;
    -moz-transform: translateX(2000px);
  }
}

@-o-keyframes bounceOutRight {
  0% {
    -o-transform: translateX(0);
  }

  20% {
    opacity: 1;
    -o-transform: translateX(-20px);
  }

  100% {
    opacity: 0;
    -o-transform: translateX(2000px);
  }
}

@keyframes bounceOutRight {
  0% {
    transform: translateX(0);
  }

  20% {
    opacity: 1;
    transform: translateX(-20px);
  }

  100% {
    opacity: 0;
    transform: translateX(2000px);
  }
}

.bounceOutRight {
  -webkit-animation-name: bounceOutRight;
  -moz-animation-name: bounceOutRight;
  -o-animation-name: bounceOutRight;
  animation-name: bounceOutRight;
}

@-webkit-keyframes rotateIn {
  0% {
    -webkit-transform-origin: center center;
    -webkit-transform: rotate(-200deg);
    opacity: 0;
  }

  100% {
    -webkit-transform-origin: center center;
    -webkit-transform: rotate(0);
    opacity: 1;
  }
}

@-moz-keyframes rotateIn {
  0% {
    -moz-transform-origin: center center;
    -moz-transform: rotate(-200deg);
    opacity: 0;
  }

  100% {
    -moz-transform-origin: center center;
    -moz-transform: rotate(0);
    opacity: 1;
  }
}

@-o-keyframes rotateIn {
  0% {
    -o-transform-origin: center center;
    -o-transform: rotate(-200deg);
    opacity: 0;
  }

  100% {
    -o-transform-origin: center center;
    -o-transform: rotate(0);
    opacity: 1;
  }
}

@keyframes rotateIn {
  0% {
    transform-origin: center center;
    transform: rotate(-200deg);
    opacity: 0;
  }

  100% {
    transform-origin: center center;
    transform: rotate(0);
    opacity: 1;
  }
}

.rotateIn {
  -webkit-animation-name: rotateIn;
  -moz-animation-name: rotateIn;
  -o-animation-name: rotateIn;
  animation-name: rotateIn;
}

@-webkit-keyframes rotateInUpLeft {
  0% {
    -webkit-transform-origin: left bottom;
    -webkit-transform: rotate(90deg);
    opacity: 0;
  }

  100% {
    -webkit-transform-origin: left bottom;
    -webkit-transform: rotate(0);
    opacity: 1;
  }
}

@-moz-keyframes rotateInUpLeft {
  0% {
    -moz-transform-origin: left bottom;
    -moz-transform: rotate(90deg);
    opacity: 0;
  }

  100% {
    -moz-transform-origin: left bottom;
    -moz-transform: rotate(0);
    opacity: 1;
  }
}

@-o-keyframes rotateInUpLeft {
  0% {
    -o-transform-origin: left bottom;
    -o-transform: rotate(90deg);
    opacity: 0;
  }

  100% {
    -o-transform-origin: left bottom;
    -o-transform: rotate(0);
    opacity: 1;
  }
}

@keyframes rotateInUpLeft {
  0% {
    transform-origin: left bottom;
    transform: rotate(90deg);
    opacity: 0;
  }

  100% {
    transform-origin: left bottom;
    transform: rotate(0);
    opacity: 1;
  }
}

.rotateInUpLeft {
  -webkit-animation-name: rotateInUpLeft;
  -moz-animation-name: rotateInUpLeft;
  -o-animation-name: rotateInUpLeft;
  animation-name: rotateInUpLeft;
}

@-webkit-keyframes rotateInDownLeft {
  0% {
    -webkit-transform-origin: left bottom;
    -webkit-transform: rotate(-90deg);
    opacity: 0;
  }

  100% {
    -webkit-transform-origin: left bottom;
    -webkit-transform: rotate(0);
    opacity: 1;
  }
}

@-moz-keyframes rotateInDownLeft {
  0% {
    -moz-transform-origin: left bottom;
    -moz-transform: rotate(-90deg);
    opacity: 0;
  }

  100% {
    -moz-transform-origin: left bottom;
    -moz-transform: rotate(0);
    opacity: 1;
  }
}

@-o-keyframes rotateInDownLeft {
  0% {
    -o-transform-origin: left bottom;
    -o-transform: rotate(-90deg);
    opacity: 0;
  }

  100% {
    -o-transform-origin: left bottom;
    -o-transform: rotate(0);
    opacity: 1;
  }
}

@keyframes rotateInDownLeft {
  0% {
    transform-origin: left bottom;
    transform: rotate(-90deg);
    opacity: 0;
  }

  100% {
    transform-origin: left bottom;
    transform: rotate(0);
    opacity: 1;
  }
}

.rotateInDownLeft {
  -webkit-animation-name: rotateInDownLeft;
  -moz-animation-name: rotateInDownLeft;
  -o-animation-name: rotateInDownLeft;
  animation-name: rotateInDownLeft;
}

@-webkit-keyframes rotateInUpRight {
  0% {
    -webkit-transform-origin: right bottom;
    -webkit-transform: rotate(-90deg);
    opacity: 0;
  }

  100% {
    -webkit-transform-origin: right bottom;
    -webkit-transform: rotate(0);
    opacity: 1;
  }
}

@-moz-keyframes rotateInUpRight {
  0% {
    -moz-transform-origin: right bottom;
    -moz-transform: rotate(-90deg);
    opacity: 0;
  }

  100% {
    -moz-transform-origin: right bottom;
    -moz-transform: rotate(0);
    opacity: 1;
  }
}

@-o-keyframes rotateInUpRight {
  0% {
    -o-transform-origin: right bottom;
    -o-transform: rotate(-90deg);
    opacity: 0;
  }

  100% {
    -o-transform-origin: right bottom;
    -o-transform: rotate(0);
    opacity: 1;
  }
}

@keyframes rotateInUpRight {
  0% {
    transform-origin: right bottom;
    transform: rotate(-90deg);
    opacity: 0;
  }

  100% {
    transform-origin: right bottom;
    transform: rotate(0);
    opacity: 1;
  }
}

.rotateInUpRight {
  -webkit-animation-name: rotateInUpRight;
  -moz-animation-name: rotateInUpRight;
  -o-animation-name: rotateInUpRight;
  animation-name: rotateInUpRight;
}

@-webkit-keyframes rotateInDownRight {
  0% {
    -webkit-transform-origin: right bottom;
    -webkit-transform: rotate(90deg);
    opacity: 0;
  }

  100% {
    -webkit-transform-origin: right bottom;
    -webkit-transform: rotate(0);
    opacity: 1;
  }
}

@-moz-keyframes rotateInDownRight {
  0% {
    -moz-transform-origin: right bottom;
    -moz-transform: rotate(90deg);
    opacity: 0;
  }

  100% {
    -moz-transform-origin: right bottom;
    -moz-transform: rotate(0);
    opacity: 1;
  }
}

@-o-keyframes rotateInDownRight {
  0% {
    -o-transform-origin: right bottom;
    -o-transform: rotate(90deg);
    opacity: 0;
  }

  100% {
    -o-transform-origin: right bottom;
    -o-transform: rotate(0);
    opacity: 1;
  }
}

@keyframes rotateInDownRight {
  0% {
    transform-origin: right bottom;
    transform: rotate(90deg);
    opacity: 0;
  }

  100% {
    transform-origin: right bottom;
    transform: rotate(0);
    opacity: 1;
  }
}

.rotateInDownRight {
  -webkit-animation-name: rotateInDownRight;
  -moz-animation-name: rotateInDownRight;
  -o-animation-name: rotateInDownRight;
  animation-name: rotateInDownRight;
}

@-webkit-keyframes rotateOut {
  0% {
    -webkit-transform-origin: center center;
    -webkit-transform: rotate(0);
    opacity: 1;
  }

  100% {
    -webkit-transform-origin: center center;
    -webkit-transform: rotate(200deg);
    opacity: 0;
  }
}

@-moz-keyframes rotateOut {
  0% {
    -moz-transform-origin: center center;
    -moz-transform: rotate(0);
    opacity: 1;
  }

  100% {
    -moz-transform-origin: center center;
    -moz-transform: rotate(200deg);
    opacity: 0;
  }
}

@-o-keyframes rotateOut {
  0% {
    -o-transform-origin: center center;
    -o-transform: rotate(0);
    opacity: 1;
  }

  100% {
    -o-transform-origin: center center;
    -o-transform: rotate(200deg);
    opacity: 0;
  }
}

@keyframes rotateOut {
  0% {
    transform-origin: center center;
    transform: rotate(0);
    opacity: 1;
  }

  100% {
    transform-origin: center center;
    transform: rotate(200deg);
    opacity: 0;
  }
}

.rotateOut {
  -webkit-animation-name: rotateOut;
  -moz-animation-name: rotateOut;
  -o-animation-name: rotateOut;
  animation-name: rotateOut;
}

@-webkit-keyframes rotateOutUpLeft {
  0% {
    -webkit-transform-origin: left bottom;
    -webkit-transform: rotate(0);
    opacity: 1;
  }

  100% {
    -webkit-transform-origin: left bottom;
    -webkit-transform: rotate(-90deg);
    opacity: 0;
  }
}

@-moz-keyframes rotateOutUpLeft {
  0% {
    -moz-transform-origin: left bottom;
    -moz-transform: rotate(0);
    opacity: 1;
  }

  100% {
    -moz-transform-origin: left bottom;
    -moz-transform: rotate(-90deg);
    opacity: 0;
  }
}

@-o-keyframes rotateOutUpLeft {
  0% {
    -o-transform-origin: left bottom;
    -o-transform: rotate(0);
    opacity: 1;
  }

  100% {
    -o-transform-origin: left bottom;
    -o-transform: rotate(-90deg);
    opacity: 0;
  }
}

@keyframes rotateOutUpLeft {
  0% {
    transform-origin: left bottom;
    transform: rotate(0);
    opacity: 1;
  }

  100% {
    -transform-origin: left bottom;
    -transform: rotate(-90deg);
    opacity: 0;
  }
}

.rotateOutUpLeft {
  -webkit-animation-name: rotateOutUpLeft;
  -moz-animation-name: rotateOutUpLeft;
  -o-animation-name: rotateOutUpLeft;
  animation-name: rotateOutUpLeft;
}

@-webkit-keyframes rotateOutDownLeft {
  0% {
    -webkit-transform-origin: left bottom;
    -webkit-transform: rotate(0);
    opacity: 1;
  }

  100% {
    -webkit-transform-origin: left bottom;
    -webkit-transform: rotate(90deg);
    opacity: 0;
  }
}

@-moz-keyframes rotateOutDownLeft {
  0% {
    -moz-transform-origin: left bottom;
    -moz-transform: rotate(0);
    opacity: 1;
  }

  100% {
    -moz-transform-origin: left bottom;
    -moz-transform: rotate(90deg);
    opacity: 0;
  }
}

@-o-keyframes rotateOutDownLeft {
  0% {
    -o-transform-origin: left bottom;
    -o-transform: rotate(0);
    opacity: 1;
  }

  100% {
    -o-transform-origin: left bottom;
    -o-transform: rotate(90deg);
    opacity: 0;
  }
}

@keyframes rotateOutDownLeft {
  0% {
    transform-origin: left bottom;
    transform: rotate(0);
    opacity: 1;
  }

  100% {
    transform-origin: left bottom;
    transform: rotate(90deg);
    opacity: 0;
  }
}

.rotateOutDownLeft {
  -webkit-animation-name: rotateOutDownLeft;
  -moz-animation-name: rotateOutDownLeft;
  -o-animation-name: rotateOutDownLeft;
  animation-name: rotateOutDownLeft;
}

@-webkit-keyframes rotateOutUpRight {
  0% {
    -webkit-transform-origin: right bottom;
    -webkit-transform: rotate(0);
    opacity: 1;
  }

  100% {
    -webkit-transform-origin: right bottom;
    -webkit-transform: rotate(90deg);
    opacity: 0;
  }
}

@-moz-keyframes rotateOutUpRight {
  0% {
    -moz-transform-origin: right bottom;
    -moz-transform: rotate(0);
    opacity: 1;
  }

  100% {
    -moz-transform-origin: right bottom;
    -moz-transform: rotate(90deg);
    opacity: 0;
  }
}

@-o-keyframes rotateOutUpRight {
  0% {
    -o-transform-origin: right bottom;
    -o-transform: rotate(0);
    opacity: 1;
  }

  100% {
    -o-transform-origin: right bottom;
    -o-transform: rotate(90deg);
    opacity: 0;
  }
}

@keyframes rotateOutUpRight {
  0% {
    transform-origin: right bottom;
    transform: rotate(0);
    opacity: 1;
  }

  100% {
    transform-origin: right bottom;
    transform: rotate(90deg);
    opacity: 0;
  }
}

.rotateOutUpRight {
  -webkit-animation-name: rotateOutUpRight;
  -moz-animation-name: rotateOutUpRight;
  -o-animation-name: rotateOutUpRight;
  animation-name: rotateOutUpRight;
}

@-webkit-keyframes rotateOutDownRight {
  0% {
    -webkit-transform-origin: right bottom;
    -webkit-transform: rotate(0);
    opacity: 1;
  }

  100% {
    -webkit-transform-origin: right bottom;
    -webkit-transform: rotate(-90deg);
    opacity: 0;
  }
}

@-moz-keyframes rotateOutDownRight {
  0% {
    -moz-transform-origin: right bottom;
    -moz-transform: rotate(0);
    opacity: 1;
  }

  100% {
    -moz-transform-origin: right bottom;
    -moz-transform: rotate(-90deg);
    opacity: 0;
  }
}

@-o-keyframes rotateOutDownRight {
  0% {
    -o-transform-origin: right bottom;
    -o-transform: rotate(0);
    opacity: 1;
  }

  100% {
    -o-transform-origin: right bottom;
    -o-transform: rotate(-90deg);
    opacity: 0;
  }
}

@keyframes rotateOutDownRight {
  0% {
    transform-origin: right bottom;
    transform: rotate(0);
    opacity: 1;
  }

  100% {
    transform-origin: right bottom;
    transform: rotate(-90deg);
    opacity: 0;
  }
}

.rotateOutDownRight {
  -webkit-animation-name: rotateOutDownRight;
  -moz-animation-name: rotateOutDownRight;
  -o-animation-name: rotateOutDownRight;
  animation-name: rotateOutDownRight;
}

@-webkit-keyframes lightSpeedIn {
  0% {
    -webkit-transform: translateX(100%) skewX(-30deg);
    opacity: 0;
  }

  60% {
    -webkit-transform: translateX(-20%) skewX(30deg);
    opacity: 1;
  }

  80% {
    -webkit-transform: translateX(0%) skewX(-15deg);
    opacity: 1;
  }

  100% {
    -webkit-transform: translateX(0%) skewX(0deg);
    opacity: 1;
  }
}

@-moz-keyframes lightSpeedIn {
  0% {
    -moz-transform: translateX(100%) skewX(-30deg);
    opacity: 0;
  }

  60% {
    -moz-transform: translateX(-20%) skewX(30deg);
    opacity: 1;
  }

  80% {
    -moz-transform: translateX(0%) skewX(-15deg);
    opacity: 1;
  }

  100% {
    -moz-transform: translateX(0%) skewX(0deg);
    opacity: 1;
  }
}

@-o-keyframes lightSpeedIn {
  0% {
    -o-transform: translateX(100%) skewX(-30deg);
    opacity: 0;
  }

  60% {
    -o-transform: translateX(-20%) skewX(30deg);
    opacity: 1;
  }

  80% {
    -o-transform: translateX(0%) skewX(-15deg);
    opacity: 1;
  }

  100% {
    -o-transform: translateX(0%) skewX(0deg);
    opacity: 1;
  }
}

@keyframes lightSpeedIn {
  0% {
    transform: translateX(100%) skewX(-30deg);
    opacity: 0;
  }

  60% {
    transform: translateX(-20%) skewX(30deg);
    opacity: 1;
  }

  80% {
    transform: translateX(0%) skewX(-15deg);
    opacity: 1;
  }

  100% {
    transform: translateX(0%) skewX(0deg);
    opacity: 1;
  }
}

.lightSpeedIn {
  -webkit-animation-name: lightSpeedIn;
  -moz-animation-name: lightSpeedIn;
  -o-animation-name: lightSpeedIn;
  animation-name: lightSpeedIn;
  -webkit-animation-timing-function: ease-out;
  -moz-animation-timing-function: ease-out;
  -o-animation-timing-function: ease-out;
  animation-timing-function: ease-out;
}

@-webkit-keyframes lightSpeedOut {
  0% {
    -webkit-transform: translateX(0%) skewX(0deg);
    opacity: 1;
  }

  100% {
    -webkit-transform: translateX(100%) skewX(-30deg);
    opacity: 0;
  }
}

@-moz-keyframes lightSpeedOut {
  0% {
    -moz-transform: translateX(0%) skewX(0deg);
    opacity: 1;
  }

  100% {
    -moz-transform: translateX(100%) skewX(-30deg);
    opacity: 0;
  }
}

@-o-keyframes lightSpeedOut {
  0% {
    -o-transform: translateX(0%) skewX(0deg);
    opacity: 1;
  }

  100% {
    -o-transform: translateX(100%) skewX(-30deg);
    opacity: 0;
  }
}

@keyframes lightSpeedOut {
  0% {
    transform: translateX(0%) skewX(0deg);
    opacity: 1;
  }

  100% {
    transform: translateX(100%) skewX(-30deg);
    opacity: 0;
  }
}

.lightSpeedOut {
  -webkit-animation-name: lightSpeedOut;
  -moz-animation-name: lightSpeedOut;
  -o-animation-name: lightSpeedOut;
  animation-name: lightSpeedOut;
  -webkit-animation-timing-function: ease-in;
  -moz-animation-timing-function: ease-in;
  -o-animation-timing-function: ease-in;
  animation-timing-function: ease-in;
}

@-webkit-keyframes hinge {
  0% {
    -webkit-transform: rotate(0);
    -webkit-transform-origin: top left;
    -webkit-animation-timing-function: ease-in-out;
  }

  20%, 60% {
    -webkit-transform: rotate(80deg);
    -webkit-transform-origin: top left;
    -webkit-animation-timing-function: ease-in-out;
  }

  40% {
    -webkit-transform: rotate(60deg);
    -webkit-transform-origin: top left;
    -webkit-animation-timing-function: ease-in-out;
  }

  80% {
    -webkit-transform: rotate(60deg) translateY(0);
    opacity: 1;
    -webkit-transform-origin: top left;
    -webkit-animation-timing-function: ease-in-out;
  }

  100% {
    -webkit-transform: translateY(700px);
    opacity: 0;
  }
}

@-moz-keyframes hinge {
  0% {
    -moz-transform: rotate(0);
    -moz-transform-origin: top left;
    -moz-animation-timing-function: ease-in-out;
  }

  20%, 60% {
    -moz-transform: rotate(80deg);
    -moz-transform-origin: top left;
    -moz-animation-timing-function: ease-in-out;
  }

  40% {
    -moz-transform: rotate(60deg);
    -moz-transform-origin: top left;
    -moz-animation-timing-function: ease-in-out;
  }

  80% {
    -moz-transform: rotate(60deg) translateY(0);
    opacity: 1;
    -moz-transform-origin: top left;
    -moz-animation-timing-function: ease-in-out;
  }

  100% {
    -moz-transform: translateY(700px);
    opacity: 0;
  }
}

@-o-keyframes hinge {
  0% {
    -o-transform: rotate(0);
    -o-transform-origin: top left;
    -o-animation-timing-function: ease-in-out;
  }

  20%, 60% {
    -o-transform: rotate(80deg);
    -o-transform-origin: top left;
    -o-animation-timing-function: ease-in-out;
  }

  40% {
    -o-transform: rotate(60deg);
    -o-transform-origin: top left;
    -o-animation-timing-function: ease-in-out;
  }

  80% {
    -o-transform: rotate(60deg) translateY(0);
    opacity: 1;
    -o-transform-origin: top left;
    -o-animation-timing-function: ease-in-out;
  }

  100% {
    -o-transform: translateY(700px);
    opacity: 0;
  }
}

@keyframes hinge {
  0% {
    transform: rotate(0);
    transform-origin: top left;
    animation-timing-function: ease-in-out;
  }

  20%, 60% {
    transform: rotate(80deg);
    transform-origin: top left;
    animation-timing-function: ease-in-out;
  }

  40% {
    transform: rotate(60deg);
    transform-origin: top left;
    animation-timing-function: ease-in-out;
  }

  80% {
    transform: rotate(60deg) translateY(0);
    opacity: 1;
    transform-origin: top left;
    animation-timing-function: ease-in-out;
  }

  100% {
    transform: translateY(700px);
    opacity: 0;
  }
}

.hinge {
  -webkit-animation-name: hinge;
  -moz-animation-name: hinge;
  -o-animation-name: hinge;
  animation-name: hinge;
}

/* originally authored by Nick Pettit - https://github.com/nickpettit/glide */

@-webkit-keyframes rollIn {
  0% {
    opacity: 0;
    -webkit-transform: translateX(-100%) rotate(-120deg);
  }

  100% {
    opacity: 1;
    -webkit-transform: translateX(0px) rotate(0deg);
  }
}

@-moz-keyframes rollIn {
  0% {
    opacity: 0;
    -moz-transform: translateX(-100%) rotate(-120deg);
  }

  100% {
    opacity: 1;
    -moz-transform: translateX(0px) rotate(0deg);
  }
}

@-o-keyframes rollIn {
  0% {
    opacity: 0;
    -o-transform: translateX(-100%) rotate(-120deg);
  }

  100% {
    opacity: 1;
    -o-transform: translateX(0px) rotate(0deg);
  }
}

@keyframes rollIn {
  0% {
    opacity: 0;
    transform: translateX(-100%) rotate(-120deg);
  }

  100% {
    opacity: 1;
    transform: translateX(0px) rotate(0deg);
  }
}

.rollIn {
  -webkit-animation-name: rollIn;
  -moz-animation-name: rollIn;
  -o-animation-name: rollIn;
  animation-name: rollIn;
}

/* originally authored by Nick Pettit - https://github.com/nickpettit/glide */

@-webkit-keyframes rollOut {
  0% {
    opacity: 1;
    -webkit-transform: translateX(0px) rotate(0deg);
  }

  100% {
    opacity: 0;
    -webkit-transform: translateX(100%) rotate(120deg);
  }
}

@-moz-keyframes rollOut {
  0% {
    opacity: 1;
    -moz-transform: translateX(0px) rotate(0deg);
  }

  100% {
    opacity: 0;
    -moz-transform: translateX(100%) rotate(120deg);
  }
}

@-o-keyframes rollOut {
  0% {
    opacity: 1;
    -o-transform: translateX(0px) rotate(0deg);
  }

  100% {
    opacity: 0;
    -o-transform: translateX(100%) rotate(120deg);
  }
}

@keyframes rollOut {
  0% {
    opacity: 1;
    transform: translateX(0px) rotate(0deg);
  }

  100% {
    opacity: 0;
    transform: translateX(100%) rotate(120deg);
  }
}

.rollOut {
  -webkit-animation-name: rollOut;
  -moz-animation-name: rollOut;
  -o-animation-name: rollOut;
  animation-name: rollOut;
}

/* N2S OWN */

.animated.popupIn {
  animation-fill-mode: both;
  -moz-animation-fill-mode: both;
  -webkit-animation-fill-mode: both;

  animation-duration: 0.2s;
  -moz-animation-duration: 0.2s;
  -webkit-animation-duration: 0.2s;
}

@-moz-keyframes popupIn {
  /* 0% {
     opacity: 0;
     @include transform(scale(1.2));
   }*/
  0% {
    opacity: 0;
    -moz-transform: scale(1);
    transform: scale(1);
  }
  80% {
    opacity: 1;
    -moz-transform: scale(1.02);
    transform: scale(1.02);
  }
  100% {
    opacity: 1;
    -moz-transform: scale(1);
    transform: scale(1);
  }
}
@-webkit-keyframes popupIn {
  /* 0% {
     opacity: 0;
     @include transform(scale(1.2));
   }*/
  0% {
    opacity: 0;
    -webkit-transform: scale(1);
    transform: scale(1);
  }
  80% {
    opacity: 1;
    -webkit-transform: scale(1.02);
    transform: scale(1.02);
  }
  100% {
    opacity: 1;
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}
@keyframes popupIn {
  /* 0% {
     opacity: 0;
     @include transform(scale(1.2));
   }*/
  0% {
    opacity: 0;
    -moz-transform: scale(1);
    -ms-transform: scale(1);
    -webkit-transform: scale(1);
    transform: scale(1);
  }
  80% {
    opacity: 1;
    -moz-transform: scale(1.02);
    -ms-transform: scale(1.02);
    -webkit-transform: scale(1.02);
    transform: scale(1.02);
  }
  100% {
    opacity: 1;
    -moz-transform: scale(1);
    -ms-transform: scale(1);
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}

.popupIn {
  animation-name: popupIn;
  -moz-animation-name: popupIn;
  -webkit-animation-name: popupIn;
}


@-webkit-keyframes notification-close {
  0% {
    opacity: 1;
    -webkit-transform: scale(1);
  }

  100% {
    opacity: 0;
    margin-top: 0px;
    margin-bottom: 0px;
    -webkit-transform: scale(0.8);
  }
}

@-moz-keyframes notification-close {
  0% {
    opacity: 1;
    -webkit-transform: scale(1);
  }

  100% {
    opacity: 0;
    margin-top: 0px;
    margin-bottom: 0px;
    -webkit-transform: scale(1.2);
  }
}

@-o-keyframes notification-close {
  0% {
    opacity: 1;
    -webkit-transform: scale(1);
  }

  100% {
    opacity: 0;
    margin-top: 0px;
    margin-bottom: 0px;
    -webkit-transform: scale(1.2);
  }
}

@keyframes notification-close {
  0% {
    opacity: 1;
    -webkit-transform: scale(1);
  }

  100% {
    opacity: 0;
    margin-top: 0px;
    margin-bottom: 0px;
    -webkit-transform: scale(1.2);
  }
}

.notification-close {
  -webkit-animation-name: notification-close;
  -moz-animation-name: notification-close;
  -o-animation-name: notification-close;
  animation-name: notification-close;
  -webkit-animation-duration: 0.3s;
  -moz-animation-duration: 0.3s;
  -ms-animation-duration: 0.3s;
  -o-animation-duration: 0.3s;
  animation-duration: 0.3s;
}

@-webkit-keyframes spin-clockwise {
  100% {
    -webkit-transform: rotate(360deg);
  }
}

@-o-keyframes spin-clockwise {
  100% {
    -o-transform: rotate(360deg);
  }
}

@-moz-keyframes spin-clockwise {
  100% {
    -moz-transform: rotate(360deg);
  }
}

@keyframes spin-clockwise {
  100% {
    transform: rotate(360deg);
  }
}

@-moz-keyframes spin-close {
  100% {
    -moz-transform: rotate(360deg);
  }
}

@-o-keyframes spin-close {
  100% {
    -o-transform: rotate(360deg);
  }
}

@keyframes spin-close {
  100% {
    transform: rotate(360deg);
  }
}

.spin-clockwise {
  -webkit-transform-origin: 50% 50%;
  -moz-transform-origin: 50% 50%;
  -o-transform-origin: 50% 50%;
  transform-origin: 50% 50%;
  -webkit-animation: spin-clockwise 1s linear infinite;
  -moz-animation: spin-clockwise 1s linear infinite;
  -o-animation: spin-clockwise 1s linear infinite;
  animation: spin-clockwise 1s linear infinite;
}

@-webkit-keyframes spin-counterclockwise {
  100% {
    -webkit-transform: rotate(-360deg);
  }
}

@-moz-keyframes counterclockwise {
  100% {
    -moz-transform: rotate(-360deg);
  }
}

@-o-keyframes counterclockwise {
  100% {
    -o-transform: rotate(-360deg);
  }
}

@keyframes counterclockwise {
  100% {
    transform: rotate(-360deg);
  }
}

.spin-counterclockwise {
  -webkit-transform-origin: 50% 50%;
  -moz-transform-origin: 50% 50%;
  -o-transform-origin: 50% 50%;
  transform-origin: 50% 50%;
  -webkit-animation: spin-counterclockwise 1s linear infinite;
  -moz-animation: spin-counterclockwise 1s linear infinite;
  -o-animation: spin-counterclockwise 1s linear infinite;
  animation: spin-counterclockwise 1s linear infinite;
}

@-webkit-keyframes jiggle {
  0% {
    -webkit-transform: translate(2px, 1px) rotate(0deg);
  }

  10% {
    -webkit-transform: translate(-1px, -2px) rotate(-1deg);
  }

  20% {
    -webkit-transform: translate(-3px, 0px) rotate(1deg);
  }

  30% {
    -webkit-transform: translate(0px, 2px) rotate(0deg);
  }

  40% {
    -webkit-transform: translate(1px, -1px) rotate(1deg);
  }

  50% {
    -webkit-transform: translate(-1px, 1px) rotate(-1deg);
  }

  60% {
    -webkit-transform: translate(-3px, -2px) rotate(0deg);
  }

  70% {
    -webkit-transform: translate(2px, 1px) rotate(-1deg);
  }

  80% {
    -webkit-transform: translate(-1px, -2px) rotate(1deg);
  }

  90% {
    -webkit-transform: translate(2px, -1px) rotate(0deg);
  }

  100% {
    -webkit-transform: translate(1px, -2px) rotate(-1deg);
  }
}

@-moz-keyframes jiggle {
  0% {
    -moz-transform: translate(2px, 1px) rotate(0deg);
  }

  10% {
    -moz-transform: translate(-1px, -2px) rotate(-1deg);
  }

  20% {
    -moz-transform: translate(-3px, 0px) rotate(1deg);
  }

  30% {
    -moz-transform: translate(0px, 2px) rotate(0deg);
  }

  40% {
    -moz-transform: translate(1px, -1px) rotate(1deg);
  }

  50% {
    -moz-transform: translate(-1px, 1px) rotate(-1deg);
  }

  60% {
    -moz-transform: translate(-3px, -2px) rotate(0deg);
  }

  70% {
    -moz-transform: translate(2px, 1px) rotate(-1deg);
  }

  80% {
    -moz-transform: translate(-1px, -2px) rotate(1deg);
  }

  90% {
    -moz-transform: translate(2px, -1px) rotate(0deg);
  }

  100% {
    -moz-transform: translate(1px, -2px) rotate(-1deg);
  }
}

@-o-keyframes jiggle {
  0% {
    -o-transform: translate(2px, 1px) rotate(0deg);
  }

  10% {
    -o-transform: translate(-1px, -2px) rotate(-1deg);
  }

  20% {
    -o-transform: translate(-3px, 0px) rotate(1deg);
  }

  30% {
    -o-transform: translate(0px, 2px) rotate(0deg);
  }

  40% {
    -o-transform: translate(1px, -1px) rotate(1deg);
  }

  50% {
    -o-transform: translate(-1px, 1px) rotate(-1deg);
  }

  60% {
    -o-transform: translate(-3px, -2px) rotate(0deg);
  }

  70% {
    -o-transform: translate(2px, 1px) rotate(-1deg);
  }

  80% {
    -o-transform: translate(-1px, -2px) rotate(1deg);
  }

  90% {
    -o-transform: translate(2px, -1px) rotate(0deg);
  }

  100% {
    -o-transform: translate(1px, -2px) rotate(-1deg);
  }
}

@keyframes jiggle {
  0% {
    transform: translate(2px, 1px) rotate(0deg);
  }

  10% {
    transform: translate(-1px, -2px) rotate(-1deg);
  }

  20% {
    transform: translate(-3px, 0px) rotate(1deg);
  }

  30% {
    transform: translate(0px, 2px) rotate(0deg);
  }

  40% {
    transform: translate(1px, -1px) rotate(1deg);
  }

  50% {
    transform: translate(-1px, 1px) rotate(-1deg);
  }

  60% {
    transform: translate(-3px, -2px) rotate(0deg);
  }

  70% {
    transform: translate(2px, 1px) rotate(-1deg);
  }

  80% {
    transform: translate(-1px, -2px) rotate(1deg);
  }

  90% {
    transform: translate(2px, -1px) rotate(0deg);
  }

  100% {
    transform: translate(1px, -2px) rotate(-1deg);
  }
}

.jiggle {
  -webkit-transform-origin: 50% 50%;
  -moz-transform-origin: 50% 50%;
  -o-transform-origin: 50% 50%;
  transform-origin: 50% 50%;
  -webkit-animation: jiggle 1s linear infinite;
  -moz-animation: jiggle 1s linear infinite;
  -o-animation: jiggle 1s linear infinite;
  animation: jiggle 1s linear infinite;
}


.animated.map-veil {
  animation-fill-mode: both;
  -moz-animation-fill-mode: both;
  -webkit-animation-fill-mode: both;

  animation-duration: 0.7s;
  -moz-animation-duration: 0.7s;
  -webkit-animation-duration: 0.7s;
}

@-moz-keyframes mapveil {
  0%, 80% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
@-webkit-keyframes mapveil {
  0%, 80% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
@keyframes mapveil {
  0%, 80% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

.map-veil {
  animation-name: mapveil;
  -moz-animation-name: mapveil;
  -webkit-animation-name: mapveil;
}


.heartbeat {
  -moz-animation-duration: 1s;
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  -moz-animation-timing-function: linear;
  -webkit-animation-timing-function: linear;
  animation-timing-function: linear;
  -moz-animation-iteration-count: infinite;
  -webkit-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
  -moz-animation-fill-mode: both;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  -moz-animation-name: heartbeat;
  -webkit-animation-name: heartbeat;
  animation-name: heartbeat;
}

@-moz-keyframes heartbeat {
  0% {
    opacity: 1;
    -moz-transform: scale(1.1);
    transform: scale(1.1);
  }
  50% {
    opacity: 0.8;
    -moz-transform: scale(0.8);
    transform: scale(0.8);
  }
  100% {
    opacity: 1;
    -moz-transform: scale(1);
    transform: scale(1);
  }
}
@-webkit-keyframes heartbeat {
  0% {
    opacity: 1;
    -webkit-transform: scale(1.1);
    transform: scale(1.1);
  }
  50% {
    opacity: 0.8;
    -webkit-transform: scale(0.8);
    transform: scale(0.8);
  }
  100% {
    opacity: 1;
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}
@keyframes heartbeat {
  0% {
    opacity: 1;
    -moz-transform: scale(1.1);
    -ms-transform: scale(1.1);
    -webkit-transform: scale(1.1);
    transform: scale(1.1);
  }
  50% {
    opacity: 0.8;
    -moz-transform: scale(0.8);
    -ms-transform: scale(0.8);
    -webkit-transform: scale(0.8);
    transform: scale(0.8);
  }
  100% {
    opacity: 1;
    -moz-transform: scale(1);
    -ms-transform: scale(1);
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}

.pulse {
  -moz-animation-name: pulse;
  -webkit-animation-name: pulse;
  animation-name: pulse;
  -moz-animation-timing-function: ease-out;
  -webkit-animation-timing-function: ease-out;
  animation-timing-function: ease-out;
  -moz-animation-iteration-count: infinite;
  -webkit-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
  -moz-animation-duration: 1s;
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
}

@-moz-keyframes pulse {
  0% {
    -moz-transform: scale(0.1, 0.1);
    transform: scale(0.1, 0.1);
    opacity: 0.0;
  }
  50% {
    opacity: 1.0;
  }
  100% {
    -moz-transform: scale(1.8, 1.8);
    transform: scale(1.8, 1.8);
    opacity: 0.0;
  }
}
@-webkit-keyframes pulse {
  0% {
    -webkit-transform: scale(0.1, 0.1);
    transform: scale(0.1, 0.1);
    opacity: 0.0;
  }
  50% {
    opacity: 1.0;
  }
  100% {
    -webkit-transform: scale(1.8, 1.8);
    transform: scale(1.8, 1.8);
    opacity: 0.0;
  }
}
@keyframes pulse {
  0% {
    -moz-transform: scale(0.1, 0.1);
    -ms-transform: scale(0.1, 0.1);
    -webkit-transform: scale(0.1, 0.1);
    transform: scale(0.1, 0.1);
    opacity: 0.0;
  }
  50% {
    opacity: 1.0;
  }
  100% {
    -moz-transform: scale(1.8, 1.8);
    -ms-transform: scale(1.8, 1.8);
    -webkit-transform: scale(1.8, 1.8);
    transform: scale(1.8, 1.8);
    opacity: 0.0;
  }
}

.button-pulse {
  -moz-animation-duration: 1s;
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  -moz-animation-timing-function: linear;
  -webkit-animation-timing-function: linear;
  animation-timing-function: linear;
  -moz-animation-iteration-count: infinite;
  -webkit-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
  -moz-animation-fill-mode: both;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  -moz-animation-name: button-pulse;
  -webkit-animation-name: button-pulse;
  animation-name: button-pulse;
}

@-moz-keyframes button-pulse {
  0% {
    opacity: 0.8;
  }
  50% {
    opacity: 0.2;
  }
  80% {
    opacity: 0.7;
  }
  100% {
    opacity: 1;
  }
}
@-webkit-keyframes button-pulse {
  0% {
    opacity: 0.8;
  }
  50% {
    opacity: 0.2;
  }
  80% {
    opacity: 0.7;
  }
  100% {
    opacity: 1;
  }
}
@keyframes button-pulse {
  0% {
    opacity: 0.8;
  }
  50% {
    opacity: 0.2;
  }
  80% {
    opacity: 0.7;
  }
  100% {
    opacity: 1;
  }
}

.auto-update {
  -webkit-transform-origin: 50% 50%;
  -moz-transform-origin: 50% 50%;
  -o-transform-origin: 50% 50%;
  transform-origin: 50% 50%;
  -webkit-animation: spin-clockwise 2s linear infinite;
  -moz-animation: spin-clockwise 2s linear infinite;
  -o-animation: spin-clockwise 2s linear infinite;
  animation: spin-clockwise 2s linear infinite;
}
