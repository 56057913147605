/** initial setup **/

.nanoscroller {
  position: relative;
  width: 100%;
  height: 100%;
  overflow-y: hidden;
  overflow-x: hidden;
  /*clip: rect(auto,auto,auto,-11px);*/

  .nano-content {
    position: absolute;
    overflow-y: scroll;
    overflow-x: visible;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;

    &:focus {
      outline: none;
    }

    &::-webkit-scrollbar {
      /*visibility: hidden;
      width:0px;
      margin:0px;*/
      display: none;
    }
  }
}

.has-scrollbar .nano-content::-webkit-scrollbar {
  display: block;
}

.nanoscroller {
  > .pane {
    background: rgba(0, 0, 0, 0);
    position: absolute;
    width: 10px;
    right: 0;
    top: 0;
    bottom: 0;
    visibility: hidden\9;

    /* Target only IE7 and IE8 with this hack */
    opacity: .01;
    -webkit-transition: .2s;
    -moz-transition: .2s;
    -o-transition: .2s;
    transition: .2s;
    -moz-border-radius: 5px;
    -webkit-border-radius: 5px;
    border-radius: 5px;

    > .slider {
      background: #444;
      background: rgba(0, 0, 0, 0.5);
      position: relative;
      margin: 0 1px;
      -moz-border-radius: 3px;
      -webkit-border-radius: 3px;
      border-radius: 3px;
    }   

  }

  &:hover > .pane {
    visibility: visible\9;

    /* Target only IE7 and IE8 with this hack */
    opacity: 0.99;
  }
}

.pane {
  &.active, &.flashed {
    visibility: visible\9;

    /* Target only IE7 and IE8 with this hack */
    opacity: 0.99;
  }
}