@font-face {
    font-family: 'score';
    src:url('../fonts/score.eot?hbni72');
    src:url('../fonts/score.eot?hbni72#iefix') format('embedded-opentype'),
        url('../fonts/score.ttf?hbni72') format('truetype'),
        url('../fonts/score.woff?hbni72') format('woff'),
        url('../fonts/score.svg?hbni72#score') format('svg');
    font-weight: normal;
    font-style: normal;
}

[class^="icon-"], [class*=" icon-"] {
    /* use !important to prevent issues with browser extensions that change fonts */
    font-family: 'score' !important;
    speak: none;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 0.8;
    font-size: 20px;

    /* Better Font Rendering =========== */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.icon-tenant:before {
  content: "\e92c";
}
.icon-user:before {
  content: "\e929";
}
.icon-pen:before {
  content: "\e92e";
}
.icon-mail2:before {
  content: "\e903";
}
.icon-like:before {
  content: "\e92a";
}
.icon-photo:before {
  content: "\e92b";
}
.icon-image:before {
  content: "\e92b";
}
.icon-heart:before {
  content: "\e91a";
}
.icon-display:before {
  content: "\e916";
}
.icon-phone2:before {
  content: "\e917";
}
.icon-mobile:before {
  content: "\e917";
}
.icon-layers:before {
  content: "\e915";
}
.icon-bulb:before {
  content: "\e904";
}
.icon-lab2:before {
  content: "\e900";
}
.icon-data:before {
  content: "\e60f";
}
.icon-connection:before {
  content: "\25";
}
.icon-mail:before {
  content: "\e609";
}
.icon-paperplane:before {
  content: "\e60a";
}
.icon-money:before {
  content: "\e60b";
}
.icon-top10:before {
  content: "\e927";
}
.icon-trophy:before {
  content: "\e927";
}
.icon-template:before {
  content: "\e928";
}
.icon-special-ticket:before {
  content: "\e926";
}
.icon-role:before {
  content: "\e925";
}
.icon-pms:before {
  content: "\e924";
}
.icon-nodata:before {
  content: "\e923";
}
.icon-meter:before {
  content: "\e922";
}
.icon-monitorization:before {
  content: "\e922";
}
.icon-issued-tickets:before {
  content: "\e921";
}
.icon-indeterminate:before {
  content: "\e920";
}
.icon-domain:before {
  content: "\e91f";
}
.icon-realm:before {
  content: "\e91f";
}
.icon-certificate:before {
  content: "\e91e";
}
.icon-license:before {
  content: "\e91e";
}
.icon-paste:before {
  content: "\e92d";
}
.icon-ticket2:before {
  content: "\e939";
}
.icon-signature:before {
  content: "\e945";
}
.icon-bell:before {
  content: "\e951";
}
.icon-drawer2:before {
  content: "\e95d";
}
.icon-quote:before {
  content: "\e978";
}
.icon-earth:before {
  content: "\e9ca";
}
.icon-heart2:before {
  content: "\e9da";
}
.icon-heart-broken:before {
  content: "\e9db";
}
.icon-play2:before {
  content: "\ea15";
}
.icon-pause2:before {
  content: "\ea16";
}
.icon-play:before {
  content: "\ea1c";
}
.icon-pause:before {
  content: "\ea1d";
}
.icon-title2:before {
  content: "\ea61";
}
.icon-page-break:before {
  content: "\ea68";
}
.icon-google:before {
  content: "\ea8c";
}
.icon-twitter:before {
  content: "\ea96";
}
.icon-linkedin:before {
  content: "\eac9";
}
.icon-magnet:before {
  content: "\e914";
}
.icon-floppy-disk:before {
  content: "\e913";
}
.icon-instagram:before {
  content: "\e90a";
}
.icon-key:before {
  content: "\e909";
}
.icon-link-external:before {
  content: "\e908";
}
.icon-bug:before {
  content: "\e906";
}
.icon-magic-wand:before {
  content: "\e612";
}
.icon-paypal:before {
  content: "\ead6";
}
.icon-facebook:before {
  content: "\e60e";
}
.icon-bullhorn:before {
  content: "\e600";
}
.icon-adinwifi:before {
  content: "\e600";
}
.icon-ticket:before {
  content: "\e603";
}
.icon-users:before {
  content: "\e930";
}
.icon-tags:before {
  content: "\21";
}
.icon-tag:before {
  content: "\22";
}
.icon-stats:before {
  content: "\23";
}
.icon-report:before {
  content: "\23";
}
.icon-filter:before {
  content: "\24";
}
.icon-portaltracker:before {
  content: "\27";
}
.icon-location:before {
  content: "\28";
}
.icon-wifiarea:before {
  content: "\28";
}
.icon-site:before {
  content: "\28";
}
.icon-cog:before {
  content: "\29";
}
.icon-settings:before {
  content: "\29";
}
.icon-menu:before {
  content: "\2a";
}
.icon-warning:before {
  content: "\2b";
}
.icon-cancel-circle:before {
  content: "\2c";
}
.icon-zoom-in:before {
  content: "\2e";
}
.icon-zoom-out:before {
  content: "\2f";
}
.icon-storm2:before {
  content: "\30";
}
.icon-eye:before {
  content: "\31";
}
.icon-eye-blocked:before {
  content: "\32";
}
.icon-pie:before {
  content: "\33";
}
.icon-bars:before {
  content: "\34";
}
.icon-radiustracker:before {
  content: "\36";
}
.icon-arrow-up:before {
  content: "\37";
}
.icon-arrow-down:before {
  content: "\38";
}
.icon-file:before {
  content: "\39";
}
.icon-folder-open:before {
  content: "\3a";
}
.icon-credit:before {
  content: "\3b";
}
.icon-phone:before {
  content: "\3c";
}
.icon-address-book:before {
  content: "\3d";
}
.icon-clock:before {
  content: "\3f";
}
.icon-title:before {
  content: "\40";
}
.icon-subtitle:before {
  content: "\41";
}
.icon-footer:before {
  content: "\42";
}
.icon-laptop:before {
  content: "\43";
}
.icon-arrow-left:before {
  content: "\45";
}
.icon-arrow-right:before {
  content: "\46";
}
.icon-pencil:before {
  content: "\a1";
}
.icon-hotspot:before {
  content: "\e605";
}
.icon-drawer:before {
  content: "\e60c";
}
.icon-download:before {
  content: "\da";
}
.icon-upload:before {
  content: "\dc";
}
.icon-shield:before {
  content: "\e607";
}
.icon-switch:before {
  content: "\e604";
}
.icon-ok:before {
  content: "\e608";
}
.icon-palette:before {
  content: "\e91d";
}
.icon-logo:before {
  content: "\e91c";
}
.icon-delete-outline:before {
  content: "\e918";
}
.icon-arrow-up-outline:before {
  content: "\e91b";
}
.icon-rss-outline:before {
  content: "\e919";
}
.icon-align-justify:before {
  content: "\e90b";
}
.icon-align-center:before {
  content: "\e90c";
}
.icon-align-left:before {
  content: "\e90d";
}
.icon-align-right:before {
  content: "\e90e";
}
.icon-vertical_align_top:before {
  content: "\e90f";
}
.icon-vertical_align_center:before {
  content: "\e910";
}
.icon-vertical_align_bottom:before {
  content: "\e911";
}
.icon-opacity:before {
  content: "\e905";
}
.icon-copy:before {
  content: "\e912";
}
.icon-info:before {
  content: "\47";
}
.icon-lock:before {
  content: "\49";
}
.icon-header:before {
  content: "\4a";
}
.icon-paperclip:before {
  content: "\4b";
}
.icon-export:before {
  content: "\4c";
}
.icon-star:before {
  content: "\4e";
}
.icon-star2:before {
  content: "\4f";
}
.icon-popup:before {
  content: "\51";
}
.icon-search:before {
  content: "\52";
}
.icon-printer:before {
  content: "\53";
}
.icon-link:before {
  content: "\54";
}
.icon-cog2:before {
  content: "\55";
}
.icon-tag3:before {
  content: "\56";
}
.icon-camera2:before {
  content: "\57";
}
.icon-new:before {
  content: "\58";
}
.icon-newspaper:before {
  content: "\59";
}
.icon-clock2:before {
  content: "\5a";
}
.icon-pie2:before {
  content: "\5c";
}
.icon-bars2:before {
  content: "\5d";
}
.icon-graph:before {
  content: "\5e";
}
.icon-checkmark:before {
  content: "\5f";
}
.icon-cross:before {
  content: "\60";
}
.icon-cancel:before {
  content: "\60";
}
.icon-minus:before {
  content: "\61";
}
.icon-plus:before {
  content: "\62";
}
.icon-cross2:before {
  content: "\63";
}
.icon-minus2:before {
  content: "\64";
}
.icon-plus2:before {
  content: "\65";
}
.icon-cross3:before {
  content: "\66";
}
.icon-minus3:before {
  content: "\67";
}
.icon-blocked:before {
  content: "\68";
}
.icon-cycle:before {
  content: "\69";
}
.icon-cw:before {
  content: "\6a";
}
.icon-ccw:before {
  content: "\6b";
}
.icon-shuffle:before {
  content: "\6c";
}
.icon-arrow:before {
  content: "\6d";
}
.icon-arrow2:before {
  content: "\6e";
}
.icon-retweet:before {
  content: "\6f";
}
.icon-loop:before {
  content: "\70";
}
.icon-history:before {
  content: "\71";
}
.icon-switch2:before {
  content: "\73";
}
.icon-list:before {
  content: "\74";
}
.icon-add-to-list:before {
  content: "\75";
}
.icon-trash:before {
  content: "\76";
}
.icon-arrow-left4:before {
  content: "\77";
}
.icon-arrow-down5:before {
  content: "\78";
}
.icon-select-arrow:before {
  content: "\e92f";
}
.icon-arrow-up4:before {
  content: "\79";
}
.icon-arrow-right4:before {
  content: "\7a";
}
.icon-vcard:before {
  content: "\7b";
}
.icon-login:before {
  content: "\7d";
}
.icon-warning3:before {
  content: "\7e";
}
.icon-comment:before {
  content: "\100";
}
.icon-logout:before {
  content: "\101";
}
.icon-help:before {
  content: "\102";
}
.icon-chat:before {
  content: "\103";
}
.icon-lock-open:before {
  content: "\104";
}
.icon-question:before {
  content: "\105";
}
.icon-bolt:before {
  content: "\107";
}
.icon-layout:before {
  content: "\108";
}
.icon-statistics:before {
  content: "\119";
}
.icon-plus3:before {
  content: "\133";
}
.icon-forward:before {
  content: "\155";
}
.icon-reply:before {
  content: "\15b";
}
.icon-arrow-up8:before {
  content: "\15c";
}
.icon-arrow-down8:before {
  content: "\17a";
}
.icon-arrow-left7:before {
  content: "\1ca";
}
.icon-arrow-right3:before {
  content: "\ac";
}
.icon-menu3:before {
  content: "\bf";
}
.icon-erase:before {
  content: "\e9";
}
.icon-legalterms:before {
  content: "\e907";
}
.icon-calendar:before {
  content: "\5b";
}
.icon-cc-visa:before {
  content: "\e611";
}
.icon-chevron-left:before {
  content: "\ed";
}
.icon-chevron-right:before {
  content: "\f3";
}
.icon-minus-sign:before {
  content: "\fa";
}
.icon-plus-sign:before {
  content: "\132";
}
.icon-connections:before {
  content: "\e902";
}
.icon-locations:before {
  content: "\e901";
}
.icon-group:before {
  content: "\e901";
}
.icon-wlangroup:before {
  content: "\e902";
}
.icon-content_filtering:before {
  content: "\e931";
}
.icon-integrations:before {
  content: "\e932";
}
.icon-easy_authentication:before {
  content: "\e933";
}
.icon-advanced_reports:before {
  content: "\e934";
}
.icon-medal:before {
  content: "\e935";
}
.icon-flag:before {
  content: "\e936";
}
.icon-global-users:before {
  content: "\e937";
}
.icon-registered-users:before {
  content: "\e938";
}
.icon-group-users:before {
  content: "\e93a";
}
.icon-scale:before {
  content: "\e93b";
}
.icon-legal-data:before {
  content: "\e93c";
}
.icon-exchange:before {
  content: "\e93d";
}
.icon-vip-star:before {
  content: "\e93e";
}
.icon-loyalty:before {
  content: "\e93f";
}
.icon-reminder:before {
  content: "\e940";
}
.icon-birthday:before {
  content: "\e941";
}
.icon-first_register:before {
  content: "\e942";
}
.icon-messaging-campaign:before {
  content: "\e943";
}
.icon-content:before {
  content: "\e944";
}
.icon-template-mail:before {
  content: "\e946";
}
.icon-legal-component:before {
  content: "\e94a";
}
.icon-post-login:before {
  content: "\e947";
}
.icon-while-login:before {
  content: "\e948";
}
.icon-pre-login:before {
  content: "\e949";
}
.icon-postlogin:before {
  content: "\e94b";
}
.icon-prelogin:before {
  content: "\e94c";
}
.icon-survey-answers:before {
  content: "\e94d";
}
.icon-surveys:before {
  content: "\e94e";
}
.icon-survey-reports:before {
  content: "\e94f";
}
.icon-authservers:before {
  content: "\e950";
}
.icon-office:before {
  content: "\e952";
}
.icon-use-terms:before {
  content: "\e953";
}
.icon-privacy-terms:before {
  content: "\e954";
}
.icon-contract:before {
  content: "\e955";
}
.icon-digital-content:before {
  content: "\e956";
}
.icon-digital-content-reports:before {
  content: "\e957";
}
.icon-survey:before {
  content: "\e958";
}
.icon-segment:before {
  content: "\e959";
}
.icon-reset-password:before {
  content: "\e95a";
}
.icon-account:before {
  content: "\e95b";
}
.icon-mac-input:before {
  content: "\e95c";
}
.icon-account-new:before {
  content: "\e95e";
}
.icon-account-list:before {
  content: "\e95f";
}
.icon-mac-input-new:before {
  content: "\e960";
}
.icon-mac-input-list:before {
  content: "\e961";
}
.icon-mac-input-blacklist:before {
  content: "\e962";
}
.icon-access-profile:before {
  content: "\e963";
}
.icon-management:before {
  content: "\e964";
}
.icon-cityscape:before {
  content: "\e965";
}
.icon-octopus-api_rest:before {
  content: "\e966";
}
.icon-octopus-pms_integration:before {
  content: "\e967";
}
.icon-octopus-content_filtering:before {
  content: "\e968";
}
.icon-octopus-marketing:before {
  content: "\e969";
}
.icon-octopus-easy_authentication:before {
  content: "\e96a";
}
.icon-octopus-standard:before {
  content: "\e96b";
}
.icon-face1:before {
  content: "\e96c";
}
.icon-face2:before {
  content: "\e96d";
}
.icon-face3:before {
  content: "\e96e";
}
.icon-face4:before {
  content: "\e96f";
}
.icon-face5:before {
  content: "\e970";
}
.icon-comment2:before {
  content: "\e971";
}
.icon-saml:before {
  content: "\e972";
}
.icon-azure:before {
  content: "\e973";
}
.icon-access:before {
  content: "\e974";
}
.icon-phone3:before {
  content: "\e975";
}
.icon-notification-on:before {
  content: "\e976";
}
.icon-notification-off:before {
  content: "\e977";
}
.icon-whatsapp:before {
  content: "\e979";
}