@import "globals.scss";

html, body {
    width: 100%;
    height: 100%;
    font-family: Lato, sans-serif;
    color: #444;
    -webkit-font-smoothing: antialiased;
    background: #E8E8E8;
	  font-size: 14px;
    margin:0;
}


.app-header {
    background-color: $navBackground;
    top: 0;
    left: 0;
    z-index: 101;
    width: 100%;
    min-width: 1024px;
	  height: 40px;
    border-bottom: 1px solid $hrMediumColor;
}

.app-logo {
    padding-right: 10px;
    padding-top: 4px;
    padding-bottom: 4px;
    padding-left: 30px;
    img{
      height: 30px;
    }
}

.login_logo{
  text-align: center;
  position: absolute;
  top: calc(50% - 230px);
  transform: translateY(-50%) translateX(-50%);
  left: 50%;
  img{
    height: 40px;
  }
}

#container, #loginContainer {
    width: 380px;
    margin: 50px auto 0px;
    background: #FFF;
    border-radius: 4px;
    border: 1px solid $hrMediumColor;
    box-shadow: 0 1px 2px rgba(0, 0, 0, .1);
}

#loginContainer {
    width: 450px;
    margin: 0px auto 0px;
    border: none;
    box-shadow: 0 1px 10px rgba(0,0,0,0.5);
    position: absolute;
    top: 50%;
    transform: translateY(-50%) translateX(-50%);
    left: 50%;

}

#login_notifications{
  text-align: center;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: calc(50% + 250px);
  background: #FFF;
  width: 410px;
  border-radius: 4px;
  padding-bottom: 20px;
  padding-left: 20px;
  padding-right: 20px;
}

/* BUTTONS */
button, .btn{
  background-color:$highlightColor;
  font-size:14px;
  border-radius: 0.3em;
  border:none;
  padding:4px 10px;
  overflow:hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width:100%;
  margin:5px;
  outline:0;
  color: white;
  cursor:pointer;
  display: inline-block;

  &.small{
    padding:3px 8px;
    font-size:12px;
  }

  &.tiny{
    padding:2px 4px;
    font-size:10px;
  }

   &[disabled]{
    opacity:0.5;
    cursor:not-allowed;
  }

  &:not([disabled]):hover{
    box-shadow: 0px 2px 2px rgba(0,0,0,0.5);
  }

  &.alert{
      background-color: $alertColor;
  }

  &.round{
    @include border-radius(50%);
    padding: 17.5px 0px;
    margin: 2px;
    opacity:.8;
    width: 52px;
    overflow:hidden;
    font-style:normal;
    font-weight:bold;
    text-align:center;

    &.left-btn{
      border-radius: 30% 0 0 30%;
    }

    &.middle-btn{
      border-radius: 0%;
      margin-left:0px;
      margin-right:0px;
    }

    &.right-btn{
      border-radius: 0% 30% 30% 0%;
    }


    &:hover{
      opacity:1;
      box-shadow:none;
    }
  }
}

.loginBtn{
  padding:10px 15px;
}


form {
    margin: 15px 20px 30px;

    header{
      display:inline-block;
      width:100%;
      
      h1{
        font-weight:normal;
        margin:0px;
      }
    }

    hr{
      border:none;
      height:1px;
      margin-top:20px;
      background-color: $hrMediumColor;
    }

}

.loginForm{
  margin: 6px 54px 11px;
}

.recoverForm {
  margin: 20px 54px 11px;
  h1 {
    text-align: center;
  }
}

.email-sent {
  margin: 20px 20px 11px;
  h1 {
    text-align: center;
  }
}

.resetPasswordForm {
  margin: 20px 20px 30px;
  h1 {
    text-align: center;
  }
}

.login-error {
  color: $alertColor;
  height:30px;
  padding-top:15px;
  text-align:center;
  &.error-text {
    margin-left: 10px;
  }
}

label {
    color: $mainColor;
    display: block;
    margin-left:5px;
    margin-top: 18px;
    padding-top: 1px;
    font-size: 14px;
    margin-bottom:10px;
}

input[type=text], input[type=number], input[type=password], input[type=email], input[type=url]{

  background-color:#E8E8E8;
  font-size:16px;
  border-radius: 0.3em;
  border:none;
  padding:0.4em;
  font-family:Lato;
  color: $mainColor;
  width:100%;
  box-sizing:border-box;
  border:2px solid transparent;

  &:hover{
    border-color: #E0E0E0;    
  }

  &:focus{
    outline:none;
    background-color: $light40Color;
    color: $highlightColor;
    border-color:transparent;
  }

  &.ng-invalid{
    background-color:$inputAlertBackground;
    color:$alertColor;

    &:focus{
       background-color:$lightAlertColor;
    }
  }
}

input[type="checkbox"]{
  position: absolute;
  left: -9999px;
}

input[type="checkbox"] + label{
  position: relative;
  padding-left: 2.5em;
  cursor: pointer;
  font-size:14px;

  &:before{
    content: '';
    position: absolute;
    left:0px;
    top:-0.25em;
    padding:0.15em;
    width: 1.5em;
    height:1.5em;
    background: $controlBackground;
    border-radius: 0.2em;          
  }

  &:after{
    content: '_';
    font-family:'score';
    position: absolute;
    top: 0em;
    left: 0.125em;
    font-size: 1.5em;
    line-height: 0.9;          
    transition: all .2s;
    color: $highlightColor;
  }
}

input[type="checkbox"]:not(:checked) + label:after {
  opacity: 0;
  
}

input[type="checkbox"]:checked + label:after {
  opacity: 1;
}


input[type="checkbox"]:disabled + label {
  opacity:0.5;
}

input[type="checkbox"]:focus + label {
  color: $highlightColor;
}

input[type="radio"]{
  position: absolute;
  left: -9999px;
}

input[type="radio"] + label{
  position: relative;
  padding-left: 2.5em;
  cursor: pointer;
  font-size:14px;

  &:before{
    content: '';
    position: absolute;
    left:0px;
    top:-0.25em;
    padding:0.15em;
    width: 1.5em;
    height:1.5em;
    background: $controlBackground;
    border-radius: 0.2em;
  }

  &:after{
    content: '_';
    font-family:'score';
    position: absolute;
    top: 0em;
    left: 0.125em;
    font-size: 1.5em;
    line-height: 0.9;
    transition: all .2s;
    color: $highlightColor;
  }
}

input[type="radio"]:not(:checked) + label:after {
  opacity: 0;
}

input[type="radio"]:checked + label:after {
  opacity: 1;
}

input[type="radio"]:disabled + label {
  opacity:0.5;
}

input[type="radio"]:focus + label {
  color: $highlightColor;
}

.submit {
	margin-top: 18px;
  text-align: right;
}

.loginSubmit {
  text-align: center;
}

.footerForm {
  margin-left: 18px;
  margin-right: 10px;
  margin-top: 10px;
}


.loginFooterForm {
	margin-top: 10px;
  text-align: center;
  p{
    text-align: center;
    font-size: 11px;
    margin-top: 20px;
    margin-bottom: 0;
  }
}

a.login-link {
    cursor: pointer;
    color: $highlightColor;
    text-decoration: none;
}

a.login-link:hover {
    color: $highlightColor;
    text-decoration: underline;
}

.messageForm {
	margin-top: 15px;
	margin-left: 18px;
	margin-right: 15px;
	font-size:14px;
	color: $highlightColor;
}

.footer {
  margin-top:50px;
  width: 100%;    
  bottom: 0px;
  padding: 10px;
  height:10px;
  color: #666;
  font-size: 10px;
  text-align: center;
  text-transform: uppercase;

  position: fixed; // Remove for a JIRA-like footer.
  bottom:0px; // Remove for a JIRA-like footer.
  border-top: 1px solid $hrMediumColor; // Remove for a JIRA-like footer.
  background-color: $footerBackground; // Remove for a JIRA-like footer 
}

.twoFactorLogout {
  font-size: 14px !important;
  float: left;
  text-decoration: none;
  margin-left: 10px;
}

.buttonNotificationsLogin{
  position:fixed;
  width:60px;
  height:60px;
  bottom:40px;
  right:40px;
  background-color:$highlightColor;
  color:#FFF;
  border-radius:50px;
  text-align:center;
  box-shadow: 2px 2px 3px #999;
}

.buttonNotificationBell{
  margin-top:22px;
}

.icon-btn{
  display: inline-block;
  position: relative;
  background-color: $highlightColor;
  padding: 4px;
  border-radius: 2em;
  color: $pageHeaderBackground;
  width: 1.8em;
  height: 1.8em;
  text-align: center;
  font-size: 25px !important;
  line-height: 1.8em !important;
  margin: 2px;
  vertical-align: top;
  opacity: .8;
  -webkit-transition: width .1s,background-color 1s,color 1s;
  -moz-transition: width .1s,background-color 1s,color 1s;
  transition: width .1s,background-color 1s,color 1s;
  -webkit-touch-callout: none;
  @include user-select(none);
  cursor:pointer;

  &:hover{
    opacity:1;
  }

  &.undo{
    width:70px;
    &:after{
      content:",";
      margin-left:10px;
      font-size: 20px;
      position:relative;
      top:-1px;
      opacity:0.8;
    }
  }


  &.alert{
    background-color: $alertColor;
  }

  &.medium{
    padding: 7px;
    width: 26px;
    height: 26px;
    font-size: 21px !important;
    line-height: 25px !important;
    margin: 1px;
  }

  &.small{
    padding: 4px;
    width: 18px;
    height: 18px;
    font-size: 16px !important;
    line-height: 18px !important;
    margin: 1px;
  }

  &.tiny{
    padding: 4px;
    width: 14px;
    height: 14px;
    font-size: 14px !important;
    line-height: 14px !important;
    margin: 1px;
  }

  &.table-size{
    padding: 4px;
    width: 16px;
    height: 16px;
    font-size: 16px !important;
    line-height: 16px !important;
    margin: 2px;
  }

  &.hollow{
    display:inline-block;
    background-color: transparent;
    padding:3px;
    border-radius:2em;
    color: $highlightColor;
    width: 40px;
    height: 40px;
    text-align:center;
    font-size: 25px;
    line-height: 42px !important;
    border: 3px solid $highlightColor;
    vertical-align:top;
    opacity:0.8;
    -webkit-transition: width 0.1s, background-color 1s, color 1s;
    -moz-transition: width 0.1s, background-color 1s, color 1s;
    transition: width 0.1s, background-color 1s, color 1s;
    -webkit-touch-callout: none;
    @include user-select(none);
    cursor:pointer;


    &.alert{
      color: $alertColor;
      border: 3px solid $alertColor;
    }

    &.medium{
      padding: 7px;
      width: 26px;
      height: 26px;
      font-size: 21px !important;
      line-height: 25px !important;
      margin: 1px;
    }

    &.small{
      padding: 3px;
      font-weight:bold;
      width: 18px;
      height: 18px;
      font-size: 14px !important;
      line-height: 18px !important;
      margin: 1px;
    }

    &.tiny{
      padding: 1px;
      font-weight: bold;
      width: 14px;
      height: 14px;
      font-size: 14px !important;
      line-height: 14px !important;
      margin: 1px;
    }

    &.table-size{
      padding: 3px;
      width: 14px;
      height: 14px;
      font-size: 14px !important;
      line-height: 14px !important;
      margin: 1px;
      font-weight: bold;
    }

  }

  &.waiting{
    cursor:wait;
    font-size:27px !important; /* hollow */
    line-height:46.5px !important;   /* hollow */

    -webkit-animation-fill-mode: both;
    -moz-animation-fill-mode: both;
    -ms-animation-fill-mode: both;
    -o-animation-fill-mode: both;
    animation-fill-mode: both;

    -webkit-animation-duration: 1s;
    -moz-animation-duration: 1s;
    -ms-animation-duration: 1s;
    -o-animation-duration: 1s;
    animation-duration: 1s;

    -webkit-transform-origin: 50% 50%;
    -moz-transform-origin: 50% 50%;
    -o-transform-origin: 50% 50%;
    transform-origin: 50% 50%;

    -webkit-animation: spin-clockwise 1s linear infinite;
    -moz-animation: spin-clockwise 1s linear infinite;
    -o-animation: spin-clockwise 1s linear infinite;
    animation: spin-clockwise 1s linear infinite;
  }

  &.waiting:before{
    font-family:score;
    content:'i';
  }

  &.hollow.waiting{
    line-height:42.5px !important;   /* hollow */
  }
}

.context-popup-data {
  float: left;
  //border-left: 2px solid #eeeeee;
  //width: 360px;
  width: 480px;
  padding: 10px 0px 0px 10px;
  margin: 10px 10px 0px 10px;

  .context-popup-description {
    font-size:20px;
    font-weight: 300;
    overflow: hidden;
    text-overflow:ellipsis;
    white-space: nowrap;
    margin-top: -10px;
    margin-bottom:5px;

    &.selectable{
      cursor:pointer;
      padding: 2px 5px;

      &:hover{
        background-color: $light40Color;
        color: $highlightColor;
        border-radius: 0.2em;
      }

      &:after{
        font-family:'score';
        content:'x';
        float: right;
        opacity: 0.5;
        margin-top:6px;
      }
    }

  }

  .context-popup-detail{
    color: #777;
    font-size:14px;
    font-style: italic;
    margin-bottom: 10px;
  }

  &.multi{
    display:inline-block;
    padding-top:5px;
    border-left:none;
    width:270px;
  }

}

.context-popup-wrapper{
  width:520px;
  overflow:hidden;

  &.wlan-context{
    width:440px;

    .context-popup-data{
      width: 400px;
    }
  }
}

.context-popup-table{
  font-size:14px;
  padding: 0px 10px;
  margin: 10px;

  .details{
    opacity:0.5;
    font-size: 12px;
    margin:0px;
    white-space:nowrap;
    text-overflow:ellipsis;
    overflow:hidden;
  }

}

div.notification-exception{
  height:100%;
  position:relative;
  width:100%;
  text-align:center;

  h1{
    font-size:20px;
    text-align:center;
    color: $highlightColor;
    font-weight:300;
    position:relative;
    top:10px;
    width:100%;
  }

  div[class^="icon-"], div[class*=" icon-"]{
    margin:10px auto 0px;
    font-size:60px;
    line-height:70px;
    width:80px;
    height:80px;
    border-radius:50%;
    background-color: $highlightColor;
    padding:10px;
    opacity:0.3;
    color:white;
  }

}



@import 'table.scss';
@import '../js/Angular/Directives/Popup/Popup.scss';


