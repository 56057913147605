/* SASS MAIN VARIABLES */
.random-password-container .random-password {
  text-decoration: none;
  display: inline-block;
  font-size: 14px;
  margin-left: 10px;
  cursor: pointer;
}
.random-password-container .random-password i {
  display: table-cell;
}
.random-password-container .random-password span {
  display: table-cell;
  vertical-align: middle;
  padding-left: 5px;
}

html, body {
  width: 100%;
  height: 100%;
  font-family: Lato, sans-serif;
  color: #444;
  -webkit-font-smoothing: antialiased;
  background: #E8E8E8;
  font-size: 14px;
  margin: 0;
}

.app-header {
  background-color: var(--nav-bkg);
  top: 0;
  left: 0;
  z-index: 101;
  width: 100%;
  min-width: 1024px;
  height: 40px;
  border-bottom: 1px solid var(--hr-medium-clr);
}

.app-logo {
  padding-right: 10px;
  padding-top: 4px;
  padding-bottom: 4px;
  padding-left: 30px;
}
.app-logo img {
  height: 30px;
}

.login_logo {
  text-align: center;
  position: absolute;
  top: calc(50% - 230px);
  transform: translateY(-50%) translateX(-50%);
  left: 50%;
}
.login_logo img {
  height: 40px;
}

#container, #loginContainer {
  width: 380px;
  margin: 50px auto 0px;
  background: #FFF;
  border-radius: 4px;
  border: 1px solid var(--hr-medium-clr);
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
}

#loginContainer {
  width: 450px;
  margin: 0px auto 0px;
  border: none;
  box-shadow: 0 1px 10px rgba(0, 0, 0, 0.5);
  position: absolute;
  top: 50%;
  transform: translateY(-50%) translateX(-50%);
  left: 50%;
}

#login_notifications {
  text-align: center;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: calc(50% + 250px);
  background: #FFF;
  width: 410px;
  border-radius: 4px;
  padding-bottom: 20px;
  padding-left: 20px;
  padding-right: 20px;
}

/* BUTTONS */
button, .btn {
  background-color: var(--hghlght-clr);
  font-size: 14px;
  border-radius: 0.3em;
  border: none;
  padding: 4px 10px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 100%;
  margin: 5px;
  outline: 0;
  color: white;
  cursor: pointer;
  display: inline-block;
}
button.small, .btn.small {
  padding: 3px 8px;
  font-size: 12px;
}
button.tiny, .btn.tiny {
  padding: 2px 4px;
  font-size: 10px;
}
button[disabled], .btn[disabled] {
  opacity: 0.5;
  cursor: not-allowed;
}
button:not([disabled]):hover, .btn:not([disabled]):hover {
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.5);
}
button.alert, .btn.alert {
  background-color: var(--alert-clr);
}
button.round, .btn.round {
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  border-radius: 50%;
  padding: 17.5px 0px;
  margin: 2px;
  opacity: 0.8;
  width: 52px;
  overflow: hidden;
  font-style: normal;
  font-weight: bold;
  text-align: center;
}
button.round.left-btn, .btn.round.left-btn {
  border-radius: 30% 0 0 30%;
}
button.round.middle-btn, .btn.round.middle-btn {
  border-radius: 0%;
  margin-left: 0px;
  margin-right: 0px;
}
button.round.right-btn, .btn.round.right-btn {
  border-radius: 0% 30% 30% 0%;
}
button.round:hover, .btn.round:hover {
  opacity: 1;
  box-shadow: none;
}

.loginBtn {
  padding: 10px 15px;
}

form {
  margin: 15px 20px 30px;
}
form header {
  display: inline-block;
  width: 100%;
}
form header h1 {
  font-weight: normal;
  margin: 0px;
}
form hr {
  border: none;
  height: 1px;
  margin-top: 20px;
  background-color: var(--hr-medium-clr);
}

.loginForm {
  margin: 6px 54px 11px;
}

.recoverForm {
  margin: 20px 54px 11px;
}
.recoverForm h1 {
  text-align: center;
}

.email-sent {
  margin: 20px 20px 11px;
}
.email-sent h1 {
  text-align: center;
}

.resetPasswordForm {
  margin: 20px 20px 30px;
}
.resetPasswordForm h1 {
  text-align: center;
}

.login-error {
  color: var(--alert-clr);
  height: 30px;
  padding-top: 15px;
  text-align: center;
}
.login-error.error-text {
  margin-left: 10px;
}

label {
  color: #555;
  display: block;
  margin-left: 5px;
  margin-top: 18px;
  padding-top: 1px;
  font-size: 14px;
  margin-bottom: 10px;
}

input[type=text], input[type=number], input[type=password], input[type=email], input[type=url] {
  background-color: #E8E8E8;
  font-size: 16px;
  border-radius: 0.3em;
  border: none;
  padding: 0.4em;
  font-family: Lato;
  color: #555;
  width: 100%;
  box-sizing: border-box;
  border: 2px solid transparent;
}
input[type=text]:hover, input[type=number]:hover, input[type=password]:hover, input[type=email]:hover, input[type=url]:hover {
  border-color: #E0E0E0;
}
input[type=text]:focus, input[type=number]:focus, input[type=password]:focus, input[type=email]:focus, input[type=url]:focus {
  outline: none;
  background-color: var(--light40-clr);
  color: var(--hghlght-clr);
  border-color: transparent;
}
input[type=text].ng-invalid, input[type=number].ng-invalid, input[type=password].ng-invalid, input[type=email].ng-invalid, input[type=url].ng-invalid {
  background-color: var(--input-alert-bkg);
  color: var(--alert-clr);
}
input[type=text].ng-invalid:focus, input[type=number].ng-invalid:focus, input[type=password].ng-invalid:focus, input[type=email].ng-invalid:focus, input[type=url].ng-invalid:focus {
  background-color: var(--light-alert-clr);
}

input[type=checkbox] {
  position: absolute;
  left: -9999px;
}

input[type=checkbox] + label {
  position: relative;
  padding-left: 2.5em;
  cursor: pointer;
  font-size: 14px;
}
input[type=checkbox] + label:before {
  content: "";
  position: absolute;
  left: 0px;
  top: -0.25em;
  padding: 0.15em;
  width: 1.5em;
  height: 1.5em;
  background: var(--control-bkg);
  border-radius: 0.2em;
}
input[type=checkbox] + label:after {
  content: "_";
  font-family: "score";
  position: absolute;
  top: 0em;
  left: 0.125em;
  font-size: 1.5em;
  line-height: 0.9;
  transition: all 0.2s;
  color: var(--hghlght-clr);
}

input[type=checkbox]:not(:checked) + label:after {
  opacity: 0;
}

input[type=checkbox]:checked + label:after {
  opacity: 1;
}

input[type=checkbox]:disabled + label {
  opacity: 0.5;
}

input[type=checkbox]:focus + label {
  color: var(--hghlght-clr);
}

input[type=radio] {
  position: absolute;
  left: -9999px;
}

input[type=radio] + label {
  position: relative;
  padding-left: 2.5em;
  cursor: pointer;
  font-size: 14px;
}
input[type=radio] + label:before {
  content: "";
  position: absolute;
  left: 0px;
  top: -0.25em;
  padding: 0.15em;
  width: 1.5em;
  height: 1.5em;
  background: var(--control-bkg);
  border-radius: 0.2em;
}
input[type=radio] + label:after {
  content: "_";
  font-family: "score";
  position: absolute;
  top: 0em;
  left: 0.125em;
  font-size: 1.5em;
  line-height: 0.9;
  transition: all 0.2s;
  color: var(--hghlght-clr);
}

input[type=radio]:not(:checked) + label:after {
  opacity: 0;
}

input[type=radio]:checked + label:after {
  opacity: 1;
}

input[type=radio]:disabled + label {
  opacity: 0.5;
}

input[type=radio]:focus + label {
  color: var(--hghlght-clr);
}

.submit {
  margin-top: 18px;
  text-align: right;
}

.loginSubmit {
  text-align: center;
}

.footerForm {
  margin-left: 18px;
  margin-right: 10px;
  margin-top: 10px;
}

.loginFooterForm {
  margin-top: 10px;
  text-align: center;
}
.loginFooterForm p {
  text-align: center;
  font-size: 11px;
  margin-top: 20px;
  margin-bottom: 0;
}

a.login-link {
  cursor: pointer;
  color: var(--hghlght-clr);
  text-decoration: none;
}

a.login-link:hover {
  color: var(--hghlght-clr);
  text-decoration: underline;
}

.messageForm {
  margin-top: 15px;
  margin-left: 18px;
  margin-right: 15px;
  font-size: 14px;
  color: var(--hghlght-clr);
}

.footer {
  margin-top: 50px;
  width: 100%;
  bottom: 0px;
  padding: 10px;
  height: 10px;
  color: #666;
  font-size: 10px;
  text-align: center;
  text-transform: uppercase;
  position: fixed;
  bottom: 0px;
  border-top: 1px solid var(--hr-medium-clr);
  background-color: var(--footer-bkg);
}

.twoFactorLogout {
  font-size: 14px !important;
  float: left;
  text-decoration: none;
  margin-left: 10px;
}

.buttonNotificationsLogin {
  position: fixed;
  width: 60px;
  height: 60px;
  bottom: 40px;
  right: 40px;
  background-color: var(--hghlght-clr);
  color: #FFF;
  border-radius: 50px;
  text-align: center;
  box-shadow: 2px 2px 3px #999;
}

.buttonNotificationBell {
  margin-top: 22px;
}

.icon-btn {
  display: inline-block;
  position: relative;
  background-color: var(--hghlght-clr);
  padding: 4px;
  border-radius: 2em;
  color: var(--page-hdr-bkg);
  width: 1.8em;
  height: 1.8em;
  text-align: center;
  font-size: 25px !important;
  line-height: 1.8em !important;
  margin: 2px;
  vertical-align: top;
  opacity: 0.8;
  -webkit-transition: width 0.1s, background-color 1s, color 1s;
  -moz-transition: width 0.1s, background-color 1s, color 1s;
  transition: width 0.1s, background-color 1s, color 1s;
  -webkit-touch-callout: none;
  -moz-user-select: none;
  -ms-user-select: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  user-select: none;
  cursor: pointer;
}
.icon-btn:hover {
  opacity: 1;
}
.icon-btn.undo {
  width: 70px;
}
.icon-btn.undo:after {
  content: ",";
  margin-left: 10px;
  font-size: 20px;
  position: relative;
  top: -1px;
  opacity: 0.8;
}
.icon-btn.alert {
  background-color: var(--alert-clr);
}
.icon-btn.medium {
  padding: 7px;
  width: 26px;
  height: 26px;
  font-size: 21px !important;
  line-height: 25px !important;
  margin: 1px;
}
.icon-btn.small {
  padding: 4px;
  width: 18px;
  height: 18px;
  font-size: 16px !important;
  line-height: 18px !important;
  margin: 1px;
}
.icon-btn.tiny {
  padding: 4px;
  width: 14px;
  height: 14px;
  font-size: 14px !important;
  line-height: 14px !important;
  margin: 1px;
}
.icon-btn.table-size {
  padding: 4px;
  width: 16px;
  height: 16px;
  font-size: 16px !important;
  line-height: 16px !important;
  margin: 2px;
}
.icon-btn.hollow {
  display: inline-block;
  background-color: transparent;
  padding: 3px;
  border-radius: 2em;
  color: var(--hghlght-clr);
  width: 40px;
  height: 40px;
  text-align: center;
  font-size: 25px;
  line-height: 42px !important;
  border: 3px solid var(--hghlght-clr);
  vertical-align: top;
  opacity: 0.8;
  -webkit-transition: width 0.1s, background-color 1s, color 1s;
  -moz-transition: width 0.1s, background-color 1s, color 1s;
  transition: width 0.1s, background-color 1s, color 1s;
  -webkit-touch-callout: none;
  -moz-user-select: none;
  -ms-user-select: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  user-select: none;
  cursor: pointer;
}
.icon-btn.hollow.alert {
  color: var(--alert-clr);
  border: 3px solid var(--alert-clr);
}
.icon-btn.hollow.medium {
  padding: 7px;
  width: 26px;
  height: 26px;
  font-size: 21px !important;
  line-height: 25px !important;
  margin: 1px;
}
.icon-btn.hollow.small {
  padding: 3px;
  font-weight: bold;
  width: 18px;
  height: 18px;
  font-size: 14px !important;
  line-height: 18px !important;
  margin: 1px;
}
.icon-btn.hollow.tiny {
  padding: 1px;
  font-weight: bold;
  width: 14px;
  height: 14px;
  font-size: 14px !important;
  line-height: 14px !important;
  margin: 1px;
}
.icon-btn.hollow.table-size {
  padding: 3px;
  width: 14px;
  height: 14px;
  font-size: 14px !important;
  line-height: 14px !important;
  margin: 1px;
  font-weight: bold;
}
.icon-btn.waiting {
  cursor: wait;
  font-size: 27px !important;
  /* hollow */
  line-height: 46.5px !important;
  /* hollow */
  -webkit-animation-fill-mode: both;
  -moz-animation-fill-mode: both;
  -ms-animation-fill-mode: both;
  -o-animation-fill-mode: both;
  animation-fill-mode: both;
  -webkit-animation-duration: 1s;
  -moz-animation-duration: 1s;
  -ms-animation-duration: 1s;
  -o-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-transform-origin: 50% 50%;
  -moz-transform-origin: 50% 50%;
  -o-transform-origin: 50% 50%;
  transform-origin: 50% 50%;
  -webkit-animation: spin-clockwise 1s linear infinite;
  -moz-animation: spin-clockwise 1s linear infinite;
  -o-animation: spin-clockwise 1s linear infinite;
  animation: spin-clockwise 1s linear infinite;
}
.icon-btn.waiting:before {
  font-family: score;
  content: "i";
}
.icon-btn.hollow.waiting {
  line-height: 42.5px !important;
  /* hollow */
}

.context-popup-data {
  float: left;
  width: 480px;
  padding: 10px 0px 0px 10px;
  margin: 10px 10px 0px 10px;
}
.context-popup-data .context-popup-description {
  font-size: 20px;
  font-weight: 300;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  margin-top: -10px;
  margin-bottom: 5px;
}
.context-popup-data .context-popup-description.selectable {
  cursor: pointer;
  padding: 2px 5px;
}
.context-popup-data .context-popup-description.selectable:hover {
  background-color: var(--light40-clr);
  color: var(--hghlght-clr);
  border-radius: 0.2em;
}
.context-popup-data .context-popup-description.selectable:after {
  font-family: "score";
  content: "x";
  float: right;
  opacity: 0.5;
  margin-top: 6px;
}
.context-popup-data .context-popup-detail {
  color: #777;
  font-size: 14px;
  font-style: italic;
  margin-bottom: 10px;
}
.context-popup-data.multi {
  display: inline-block;
  padding-top: 5px;
  border-left: none;
  width: 270px;
}

.context-popup-wrapper {
  width: 520px;
  overflow: hidden;
}
.context-popup-wrapper.wlan-context {
  width: 440px;
}
.context-popup-wrapper.wlan-context .context-popup-data {
  width: 400px;
}

.context-popup-table {
  font-size: 14px;
  padding: 0px 10px;
  margin: 10px;
}
.context-popup-table .details {
  opacity: 0.5;
  font-size: 12px;
  margin: 0px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

div.notification-exception {
  height: 100%;
  position: relative;
  width: 100%;
  text-align: center;
}
div.notification-exception h1 {
  font-size: 20px;
  text-align: center;
  color: var(--hghlght-clr);
  font-weight: 300;
  position: relative;
  top: 10px;
  width: 100%;
}
div.notification-exception div[class^=icon-], div.notification-exception div[class*=" icon-"] {
  margin: 10px auto 0px;
  font-size: 60px;
  line-height: 70px;
  width: 80px;
  height: 80px;
  border-radius: 50%;
  background-color: var(--hghlght-clr);
  padding: 10px;
  opacity: 0.3;
  color: white;
}

/* SASS TABLE STYLES */
.table {
  margin-bottom: 0px;
  width: 100%;
  border-collapse: collapse;
}

.table thead {
  border-bottom: 2px solid #ddd;
}
.table thead.borderless {
  border: none;
}

.table {
  border-bottom: 2px solid #ddd;
}
.table.borderless {
  border: none;
}

.table thead td {
  text-transform: uppercase;
  color: var(--table-hdr-clr);
  font-weight: normal;
  text-decoration: none;
}

.table thead td.sortable.DESC, .table thead td.sortable.ASC {
  color: var(--dark-clr);
}

.table thead td.sortable {
  cursor: pointer;
}
.table thead td.sortable:after {
  content: "x";
  font-family: "score";
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  font-size: 15px;
  top: 2px;
  position: relative;
  color: #f2f2f2;
}
.table thead td.sortable.DESC:after, .table thead td.sortable.ASC:after {
  color: var(--dark-clr);
}
.table thead td.sortable.ASC:after {
  content: "y";
}

.table-bordered th, .table-bordered td {
  border-left: 0;
}

.table a {
  color: var(--hghlght-clr);
  font-size: smaller;
}

.table a:hover {
  text-decoration: none;
}

.table-striped tbody > tr:nth-child(odd) > td, .table-striped tbody > tr:nth-child(odd) > th {
  background: #fffff9;
}

.table tr td {
  padding: 5px;
  color: #555;
}

.table tbody > tr:not(:first-child):not([ng-repeat-end]) td {
  border-top: 1px solid #ddd;
}

.table tbody > tr:not(:first-child)[ng-repeat-end] td {
  padding: 0px;
}

td.numeric, th.numeric {
  text-align: right;
}

td.numeric {
  margin-right: 10px;
}

td.centered, th.centered {
  text-align: center;
}

table.vtop tbody th, table.vtop tbody td {
  vertical-align: top;
}

table thead th, table thead td {
  vertical-align: bottom;
  white-space: nowrap;
}

table.small-font tr td {
  padding: 5px 1px;
}

.pagination ul > li a {
  cursor: hand;
  cursor: pointer;
}

.table tbody tr.cancel > td {
  color: #AA0000;
}

.table tbody td span.siteName {
  color: #333;
  font-size: 11px;
  opacity: 0.8;
}

.table tbody td span.siteId {
  color: #333;
  font-size: 12px;
}

.table tbody tr.cancel > td span.siteName {
  color: #AA0000;
}

.table tbody tr.cancel > td span.siteId {
  color: #AA0000;
}

.table tbody tr.disabled > td {
  color: var(--alert-clr);
}

.table tr td > .icon {
  line-height: 20px;
  font-size: 20px;
  color: #777;
}
.table tr td > .icon.alert {
  color: var(--alert-clr);
}
.table tr td > .icon.highlight {
  color: var(--hghlght-clr);
}

/* PAGINATION */
.pagination {
  margin: 20px 0;
}

.pagination ul {
  display: inline-block;
  *display: inline;
  margin-bottom: 0;
  margin-left: 0;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px;
  *zoom: 1;
  -webkit-box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05);
  -moz-box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05);
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05);
}

.pagination ul > li {
  display: inline;
}

.pagination ul > li > a,
.pagination ul > li > span {
  float: left;
  padding: 4px 12px;
  line-height: 20px;
  text-decoration: none;
  background-color: #ffffff;
  border: 1px solid #dddddd;
  border-left-width: 0;
  color: var(--hghlght-clr);
}

.pagination ul > li > a:hover,
.pagination ul > .active > a,
.pagination ul > .active > span {
  background-color: #f5f5f5;
}

.pagination ul > .active > a,
.pagination ul > .active > span {
  color: #999999;
  cursor: default;
}

.pagination ul > .disabled > span,
.pagination ul > .disabled > a,
.pagination ul > .disabled > a:hover {
  color: #999999;
  cursor: default;
  background-color: transparent;
}

.pagination ul > li:first-child > a,
.pagination ul > li:first-child > span {
  border-left-width: 1px;
  -webkit-border-bottom-left-radius: 4px;
  border-bottom-left-radius: 4px;
  -webkit-border-top-left-radius: 4px;
  border-top-left-radius: 4px;
  -moz-border-radius-bottomleft: 4px;
  -moz-border-radius-topleft: 4px;
}

.pagination ul > li:last-child > a,
.pagination ul > li:last-child > span {
  -webkit-border-top-right-radius: 4px;
  border-top-right-radius: 4px;
  -webkit-border-bottom-right-radius: 4px;
  border-bottom-right-radius: 4px;
  -moz-border-radius-topright: 4px;
  -moz-border-radius-bottomright: 4px;
}

.pagination-centered {
  text-align: center;
}

.pagination-right {
  text-align: right;
}

.pagination-large ul > li > a,
.pagination-large ul > li > span {
  padding: 11px 19px;
  font-size: 17.5px;
}

.pagination-large ul > li:first-child > a,
.pagination-large ul > li:first-child > span {
  -webkit-border-bottom-left-radius: 6px;
  border-bottom-left-radius: 6px;
  -webkit-border-top-left-radius: 6px;
  border-top-left-radius: 6px;
  -moz-border-radius-bottomleft: 6px;
  -moz-border-radius-topleft: 6px;
}

.pagination-large ul > li:last-child > a,
.pagination-large ul > li:last-child > span {
  -webkit-border-top-right-radius: 6px;
  border-top-right-radius: 6px;
  -webkit-border-bottom-right-radius: 6px;
  border-bottom-right-radius: 6px;
  -moz-border-radius-topright: 6px;
  -moz-border-radius-bottomright: 6px;
}

.pagination-mini ul > li:first-child > a,
.pagination-small ul > li:first-child > a,
.pagination-mini ul > li:first-child > span,
.pagination-small ul > li:first-child > span {
  -webkit-border-bottom-left-radius: 3px;
  border-bottom-left-radius: 3px;
  -webkit-border-top-left-radius: 3px;
  border-top-left-radius: 3px;
  -moz-border-radius-bottomleft: 3px;
  -moz-border-radius-topleft: 3px;
}

.pagination-mini ul > li:last-child > a,
.pagination-small ul > li:last-child > a,
.pagination-mini ul > li:last-child > span,
.pagination-small ul > li:last-child > span {
  -webkit-border-top-right-radius: 3px;
  border-top-right-radius: 3px;
  -webkit-border-bottom-right-radius: 3px;
  border-bottom-right-radius: 3px;
  -moz-border-radius-topright: 3px;
  -moz-border-radius-bottomright: 3px;
}

.pagination-small ul > li > a,
.pagination-small ul > li > span {
  padding: 2px 10px;
  font-size: 11.9px;
}

.pagination-mini ul > li > a,
.pagination-mini ul > li > span {
  padding: 0 6px;
  font-size: 10.5px;
}

.pager {
  margin: 20px 0;
  text-align: center;
  list-style: none;
  *zoom: 1;
}

.pager:before,
.pager:after {
  display: table;
  line-height: 0;
  content: "";
}

.pager:after {
  clear: both;
}

.pager li {
  display: inline;
}

.pager li > a,
.pager li > span {
  display: inline-block;
  padding: 5px 14px;
  background-color: #fff;
  border: 1px solid #ddd;
  -webkit-border-radius: 15px;
  -moz-border-radius: 15px;
  border-radius: 15px;
}

.pager li > a:hover {
  text-decoration: none;
  background-color: #f5f5f5;
}

.pager .next > a,
.pager .next > span {
  float: right;
}

.pager .previous > a,
.pager .previous > span {
  float: left;
}

.pager .disabled > a,
.pager .disabled > a:hover,
.pager .disabled > span {
  color: #999999;
  cursor: default;
  background-color: #fff;
}

.table-search {
  height: 35px;
  background-color: var(--light40-clr);
  padding: 5px;
  border-top: 1px solid var(--light20-clr);
  border-bottom: 1px solid var(--light20-clr);
}
.table-search input[type=text] {
  background-color: rgba(255, 255, 255, 0.7);
  color: var(--hghlght-clr);
  margin-top: 4px;
  margin-left: 10px;
  border: 2px solid var(--light20-clr);
  border-radius: 1em;
  padding: 4px 20px 4px 10px;
}
.table-search input[type=text]:focus {
  background-color: white;
  color: var(--hghlght-clr);
  border: 2px solid var(--light20-clr);
}
.table-search.modal {
  margin: -10px -10px 10px -10px;
}

.table-exception {
  height: 100px;
  position: relative;
}

.table-exception-content h1 {
  font-size: 25px;
  float: left;
  margin-left: 50px;
  font-weight: 300;
  color: var(--alert-clr);
  width: 300px;
}

.table-exception-icon {
  font-size: 80px !important;
  opacity: 0.1;
  float: right;
  margin-right: 50px;
  color: #555;
}

.detail-info {
  right: 10px;
  left: 40px;
  border: none;
  border-top: 2px dotted #DDD;
}

table.detail-info-table th {
  border: none !important;
  color: var(--light10-main-clr);
  text-transform: uppercase;
  text-align: left;
  padding-right: 10px;
  vertical-align: top;
}
table.detail-info-table td {
  border: none !important;
  color: var(--hghlght-clr);
  font-weight: 700;
}

.collapse-icon {
  width: 25px;
  height: 27px;
  color: #555;
  opacity: 0.5;
  cursor: pointer;
  padding-top: 4px;
}
.collapse-icon i {
  line-height: 25px !important;
  font-size: 25px !important;
  /*padding-top: 2px;*/
}
.collapse-icon:hover {
  opacity: 1;
  color: var(--hghlght-clr);
}
.collapse-icon.spin-clockwise {
  opacity: 0.5;
  color: #555;
  cursor: wait;
}

.transfer-table-container {
  float: left;
  width: 46%;
  margin: 10px 2% 0px 2%;
}
.transfer-table-container table td {
  line-height: 14px;
}
.transfer-table-container .context-title {
  margin-left: 0px;
  line-height: 20px;
  letter-spacing: 0.3em;
}

.table-sort-icon {
  opacity: 0.8;
  cursor: pointer;
}
.table-sort-icon:hover {
  color: var(--hghlght-clr) !important;
  opacity: 1;
}
.table-sort-icon[disabled] {
  opacity: 0.2 !important;
  cursor: normal !important;
}

@CHARSET "UTF-8";
n2s-popup {
  display: block;
  position: absolute;
  top: -10000px;
  left: -10000px;
  opacity: 0;
  background-color: white;
  border: 1px solid rgba(0, 0, 0, 0.25);
  box-sizing: border-box;
  border-radius: 0.3em;
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.5);
  z-index: 3;
}
n2s-popup > div {
  margin: 5px;
  padding: 0px 0px;
  min-width: 100px;
  min-height: 20px;
  max-height: 90%;
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
}
n2s-popup .n2s-popup-footer {
  border-top: 2px solid var(--hr-medium-clr);
  text-align: right;
}
n2s-popup .n2s-popup-arrow {
  position: absolute;
  border-color: transparent;
  border-style: dotted;
  border-width: 11px;
  width: 0px;
  height: 0px;
  margin: 0px;
  padding: 0px;
  min-width: 0px;
  min-height: 0px;
  box-sizing: border-box;
}
n2s-popup .n2s-popup-arrow:after {
  position: absolute;
  border: 10px solid transparent;
  content: "";
  width: 0px;
  height: 0px;
}
n2s-popup .n2s-popup-arrow.arrow-top {
  top: -11px;
  left: 0px;
  margin-left: -11px;
  border-bottom-color: #999;
  border-bottom-color: rgba(0, 0, 0, 0.25);
  border-top-width: 0;
}
n2s-popup .n2s-popup-arrow.arrow-top:after {
  top: 1px;
  margin-left: -10px;
  border-bottom-color: #ffffff;
  border-top-width: 0;
}
n2s-popup .n2s-popup-arrow.arrow-bottom {
  bottom: -11px;
  left: 0px;
  margin-left: -11px;
  border-top-color: #999;
  border-top-color: rgba(0, 0, 0, 0.25);
  border-bottom-width: 0;
}
n2s-popup .n2s-popup-arrow.arrow-bottom:after {
  bottom: 1px;
  margin-left: -10px;
  border-top-color: #ffffff;
  border-bottom-width: 0;
}
n2s-popup .n2s-popup-arrow.arrow-left {
  top: 0px;
  left: -11px;
  margin-top: -11px;
  border-right-color: #999;
  border-right-color: rgba(0, 0, 0, 0.25);
  border-left-width: 0;
}
n2s-popup .n2s-popup-arrow.arrow-left:after {
  bottom: -10px;
  left: 1px;
  border-right-color: #ffffff;
  border-left-width: 0;
}
n2s-popup .n2s-popup-arrow.arrow-right {
  top: 0px;
  right: -11px;
  margin-top: -11px;
  border-left-color: #999;
  border-left-color: rgba(0, 0, 0, 0.25);
  border-right-width: 0;
}
n2s-popup .n2s-popup-arrow.arrow-right:after {
  right: 1px;
  bottom: -10px;
  border-left-color: #ffffff;
  border-right-width: 0;
}

[n2s-popup-trigger] {
  cursor: pointer;
}

img[n2s-popup-trigger] {
  background-image: url(../images/dropdown_arrow.png);
  background-repeat: no-repeat;
  padding-right: 18px;
  background-position: right;
}

a[n2s-popup-trigger] {
  background-image: url(../images/dropdown_arrow.png);
  background-repeat: no-repeat;
  padding-right: 18px;
  background-position: right;
  text-decoration: none;
}

.hidden {
  display: none;
}