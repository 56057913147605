@CHARSET "UTF-8";

n2s-popup {
  display: block;
  position:absolute;
  top: -10000px;
  left: -10000px;
  opacity:0;
  background-color:white;
  border: 1px solid rgba(0,0,0,0.25);
  box-sizing:border-box;
  border-radius:0.3em;             
  box-shadow: 0px 2px 10px rgba(0,0,0,0.5);
  z-index: 3;

  & > div{
    margin:5px;
    padding: 0px 0px;
  
    min-width: 100px;
    min-height: 20px;
    max-height: 90%;
    box-sizing: border-box;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;      
  }

  .n2s-popup-footer{
    border-top: 2px solid $hrMediumColor;
    text-align: right;
  }


  .n2s-popup-arrow {
    position: absolute;
    border-color: transparent;
    border-style: dotted;
    border-width: 11px;
    width: 0px;
    height: 0px;
    margin:0px;
    padding:0px;
    min-width: 0px;
    min-height: 0px;
    box-sizing: border-box;
   
    &:after{
      position:absolute;
      border: 10px solid transparent;
      content:"";
      width:0px;
      height:0px;
    }

  }

  .n2s-popup-arrow.arrow-top {
    top: -11px;
    left: 0px;
    margin-left: -11px;
    border-bottom-color: #999;
    border-bottom-color: rgba(0, 0, 0, 0.25);
    border-top-width: 0;

    &:after {
      top: 1px;
      margin-left: -10px;
      border-bottom-color: #ffffff;
      border-top-width: 0;
    }
  }


  .n2s-popup-arrow.arrow-bottom {
    bottom: -11px;
    left: 0px;
    margin-left: -11px;
    border-top-color: #999;
    border-top-color: rgba(0, 0, 0, 0.25);
    border-bottom-width: 0;

    &:after {
      bottom: 1px;
      margin-left: -10px;
      border-top-color: #ffffff;
      border-bottom-width: 0;
    }
  }

  .n2s-popup-arrow.arrow-left {
    top: 0px;
    left: -11px;
    margin-top: -11px;
    border-right-color: #999;
    border-right-color: rgba(0, 0, 0, 0.25);
    border-left-width: 0;

    &:after {
      bottom: -10px;
      left: 1px;
      border-right-color: #ffffff;
      border-left-width: 0;
    }
  }

  .n2s-popup-arrow.arrow-right {
    top: 0px;
    right: -11px;
    margin-top: -11px;
    border-left-color: #999;
    border-left-color: rgba(0, 0, 0, 0.25);
    border-right-width: 0;

    &:after {
      right: 1px;
      bottom: -10px;
      border-left-color: #ffffff;
      border-right-width: 0;
    }
  }

}

[n2s-popup-trigger]{
  cursor:pointer;
}


img[n2s-popup-trigger] {
  background-image: url(../../../../images/dropdown_arrow.png);
  background-repeat: no-repeat;
  padding-right: 18px;
  background-position: right;
}

a[n2s-popup-trigger] {
  background-image: url(../../../../images/dropdown_arrow.png);
  background-repeat: no-repeat;
  padding-right: 18px;
  background-position: right;
  text-decoration: none;
}

.hidden {
  display: none;
}
