@charset "UTF-8";


// FIXED colors.
$facebookColor: #3b5999;
$twitterColor: #000000;
$linkedinColor: #1A84BC;
$instagramColor: #1A84BC;
$googleColor: #D34836;
$officeColor: #ff5722;
$azureColor: #4bb0f1;

$okStatusColor: #3e86ce;
$alertStatusColor: #c60000;
$warningStatusColor: #f97300;

$paypalColor: rgb(0,62,133);

// COLORS as CSS vars.
$mainColor: var(--main-clr);
$highlightColor: var(--hghlght-clr);
$alertColor: var(--alert-clr);
$dangerColor: var(--danger-clr);
$hrMediumColor: var(--hr-medium-clr);
$pageHeaderBackground: var(--page-hdr-bkg);
$footerBackground: var(--footer-bkg);
$lateralPanelsBackgroundColor: var(--lateral-panels-bkg-clr);
$backgroundColor: var(--bkg-clr);
$avatarBorderColor: var(--avtr-border-clr);
$iconAvatarBackgroundColor: var(--icon-avtr-bkg-clr);
$bigAvatarBorderColor: var(--big-avtr-border-clr);
$bigIconAvatarBackgroundColor: var(--big-icon-avtr-bkg-clr);
$tableHeaderColor: var(--table-hdr-clr);
$navBackground: var(--nav-bkg);
$navOptionColor: var(--nav-opt-clr);
$navOptionDropdownColor: var(--nav-opt-dropdown-clr);
$headerNavOptionColor: var(--hdr-nav-opt-clr);
$selectedHeaderNavOptionColor: var(--slctd-hdr-nav-opt-clr);
$darkColor: var(--dark-clr);
$dark40Color: var(--dark40-clr);
$light10Color: var(--light10-clr);
$light20Color: var(--light20-clr);
$light30Color: var(--light30-clr);
$light40Color: var(--light40-clr);
$light45Color: var(--light45-clr);
$light50Color: var(--light50-clr);
$light55Color: var(--light55-clr);
$trans30Color: var(--trans30-clr);
$trans50Color: var(--trans50-clr);
$trans70Color: var(--trans70-clr);
$tableSelectionBackground: var(--table-slctn-bkg);
$controlBackground: var(--control-bkg);
$lightAlertColor: var(--light-alert-clr);
$light20AlertColor: var(--light20-alert-clr);
$lightDangerColor: var(--light-danger-clr);
$light40DangerColor: var(--light40-danger-clr);
$dark40AlertColor: var(--dark40-alert-clr);
$darkDangerColor: var(--dark-danger-clr);
$trans50AlertColor: var(--trans50-alert-clr);
$light60MainColor: var(--light60-main-clr);
$light50MainColor: var(--light50-main-clr);
$light40MainColor: var(--light40-main-clr);
$light20MainColor: var(--light20-main-clr);
$light10MainColor: var(--light10-main-clr);
$light10HrColor: var(--light10-hr-clr);
$trans70HrColor: var(--trans70-hr-clr);
$trans20HrColor: var(--trans20-hr-clr);
$light20NavOptionColor: var(--light20-nav-opt-clr);
$light20SelectedHeaderNavOptionColor: var(--light20-slctd-hdr-nav-opt-clr);
$ticketBaseColor: var(--tkt-base-clr);
$ticketHoverColor: var(--tkt-hover-clr);
$ticketBaseBackground: var(--tkt-base-bkg);
$dark10ControlBackground: var(--dark10-control-bkg);
$inputAlertBackground: var(--input-alert-bkg);
$inputDangerBackground: var(--input-danger-bkg);
$ticketHoverBackground: var(--tkt-hover-bkg);
$ticketSelectedBackground: var(--tkt-slctd-bkg);
$ticketSelectedColor: var(--tkt-slctd-clr);
$customTicketBackground: var(--custom-tkt-bkg);
$customTicketColor: var(--custom-tkt-clr);
$customTicketNameBackground: var(--custom-tkt-name-bkg);
$customTicketHoverBackground: var(--custom-tkt-hover-bkg);
$customTicketHoverColor: var(--custom-tkt-hover-clr);
$customSelectedTicketBackground: var(--custom-slctd-tkt-bkg);
$customSelectedTicketColor: var(--custom-slctd-tkt-clr);
$customSelectedTicketNameBackground: var(--custom-slctd-tkt-name-bkg);
$desatLight20Color: var(--desat-light20-clr);
$desatLight40Color: var(--desat-light40-clr);
$desatColor: var(--desat-clr);


/* SASS MAIN VARIABLES */
$mainFont: Lato, sans-serif;
$mainColor: #555;


// Main frame dimensions
$minBodyWidth: 1200px;
$leftPanelWidth: 200px;
$rightPanelWidth: 250px;
$hPadding: 25px;
$inspectorToolsWidth:50px;

$collapsedLeftPanelWidth: 60px;

$footerHeight: 35px;
$headerHeight: 40px;
$fixedFilterHeight: 40px;
$fixedFilterBigHeight: 78px;
$pageHeaderHeight: 80px;
$lateralPanelsZIndex: 100;


//@import "base-classes.scss";

@mixin border-radius($radius) {
  -webkit-border-radius: $radius;
     -moz-border-radius: $radius;
      -ms-border-radius: $radius;
          border-radius: $radius;
}

@mixin text-overflow{
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
}

@mixin round-image($diameter,$backgroundColor:white){
      @include border-radius(50%);
      width: $diameter;
      height: $diameter;
      border: 2px solid $avatarBorderColor;    
      
      @if ($diameter >= 50px){
        padding: 3px;
        border: 3px solid $avatarBorderColor;
        border-color: $bigAvatarBorderColor;  
      }
      
      @if ($backgroundColor == transparent){
        border-color: transparentize($bigAvatarBorderColor,0.4);  
      }
      
      background-color: $backgroundColor;
      box-sizing: border-box;
      margin:auto;

      img{ 
          display: block; 
          width: 100%; 
          border: 0; 
          margin: 0; 
          @include border-radius(50%);
      }
}

@mixin rect-image($width,$height,$backgroundColor:white){
      @include border-radius(.5em);
      width: $width;
      height: $height;
      border: 2px solid $avatarBorderColor;    
      
      @if (($width >= 50px) or ($height >= 50px)){
        padding: 3px;
        border: 3px solid $avatarBorderColor;
        border-color: $bigAvatarBorderColor;  
      }
      
      @if ($backgroundColor == transparent){
        border-color: transparentize($bigAvatarBorderColor,0.4);  
      }
      
      background-color: $backgroundColor;
      box-sizing: border-box;
      margin:auto;

      img{ 
          display: block; 

          @if ($width > $height){
            height:100%;  
          }else{
            width:100%;  
          }
          
          //width: 100%; 
          border: 0; 
          margin: 0;          
          @include border-radius(.5em);
      }
}

@mixin input-placeholder {
  &:-ms-input-placeholder { @content; }
  &:-moz-placeholder { @content; }
  &::-moz-placeholder { @content; }
  &::-webkit-input-placeholder { @content; }
}

@mixin user-select($val) {
  -moz-user-select: $val;
  -ms-user-select: $val;
  -webkit-user-select: $val;
  -khtml-user-select: $val;
  user-select: $val;
}

@mixin box-sizing($val) {
  box-sizing: $val;
  -moz-box-sizing: $val;
  -webkit-box-sizing: $val;
}

@mixin filter($val) {
  -webkit-filter: $val;
  -ms-filter: $val;
  filter: $val;
}

.random-password-container {
  .random-password {
    text-decoration: none;
    display: inline-block;
    font-size: 14px;
    margin-left: 10px;
    cursor: pointer;
    i {
      display: table-cell;
    }
    span {
      display: table-cell;
      vertical-align: middle;
      padding-left: 5px;
    }
  }
}