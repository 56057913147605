@charset "UTF-8";


/* SASS TABLE STYLES */
.table{
  margin-bottom: 0px;
  width:100%;
  border-collapse: collapse;
}

.table thead {
  border-bottom: 2px solid #ddd;

  &.borderless{
    border:none;
  }
}

.table {
  border-bottom: 2px solid #ddd;
  
  &.borderless{
    border:none;
  }
}

.table thead td {
  text-transform: uppercase;
  color:$tableHeaderColor;
  font-weight:normal;
  text-decoration: none;
}

.table thead td.sortable.DESC, .table thead td.sortable.ASC {
  color:$darkColor;
}

.table thead td.sortable {

  cursor:pointer;

  &:after {
    content: "\78"; 
    font-family: 'score'; 
    speak: none; 
    font-style: normal; 
    font-weight: normal; 
    font-variant: normal; 
    text-transform: none; 
    line-height: 1; 
    font-size:15px; 
    top: 2px; 
    position: relative; 
    color:#f2f2f2;
  }

  &.DESC:after,&.ASC:after {
    color:$darkColor;
  }

  &.ASC:after {
    content: "\79";
  }
}

.table-bordered th, .table-bordered td { 
  border-left: 0; 
}

.table a { 
  color:$highlightColor; 
  font-size:smaller;
}

.table a:hover { 

  text-decoration:none; 
}

.table-striped tbody > tr:nth-child(odd) > td, .table-striped tbody > tr:nth-child(odd) > th { 

  background: #fffff9; 
}

.table tr td{
  padding:5px;
  color: $mainColor;
}

.table tbody > tr:not(:first-child):not([ng-repeat-end]) td{
  border-top: 1px solid #ddd;
}

.table tbody > tr:not(:first-child)[ng-repeat-end] td{
  padding:0px;
}


td.numeric , th.numeric {
  text-align:right;
}

td.numeric{
  margin-right:10px;
}

td.centered , th.centered {
  text-align:center;
}

table.vtop tbody th, table.vtop tbody td{
  vertical-align: top;
}

table thead th, table thead td{
  vertical-align: bottom;
  white-space: nowrap;
}


table.small-font{
  tr td{
    padding:5px 1px;
  }
}

.pagination ul > li a { 
  cursor:hand; 
  cursor:pointer; 
}

.table tbody tr.cancel > td { 
  color: #AA0000;
}

.table tbody td span.siteName {
  color:#333;
  font-size:11px;
  opacity:.8;
}

.table tbody td span.siteId {

  color:#333;font-size:12px;
}

.table tbody tr.cancel > td span.siteName { 

  color: #AA0000; 
}

.table tbody tr.cancel > td span.siteId { 

  color: #AA0000; 
}

.table tbody tr.disabled > td  { color: $alertColor }

.table tr td > .icon{
  line-height:20px;
  font-size: 20px;
  color:#777;

  &.alert{
    color: $alertColor;
  }

  &.highlight{
    color: $highlightColor;
  }

}


/* PAGINATION */
.pagination {
  margin: 20px 0;
}

.pagination ul {
  display: inline-block;
  *display: inline;
  margin-bottom: 0;
  margin-left: 0;
  -webkit-border-radius: 4px;
     -moz-border-radius: 4px;
          border-radius: 4px;
  *zoom: 1;
  -webkit-box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05);
     -moz-box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05);
          box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05);
}

.pagination ul > li {
  display: inline;
}

.pagination ul > li > a,
.pagination ul > li > span {
  float: left;
  padding: 4px 12px;
  line-height: 20px;
  text-decoration: none;
  background-color: #ffffff;
  border: 1px solid #dddddd;
  border-left-width: 0;
  color:$highlightColor;
}

.pagination ul > li > a:hover,
.pagination ul > .active > a,
.pagination ul > .active > span {
  background-color: #f5f5f5;
}

.pagination ul > .active > a,
.pagination ul > .active > span {
  color: #999999;
  cursor: default;
}

.pagination ul > .disabled > span,
.pagination ul > .disabled > a,
.pagination ul > .disabled > a:hover {
  color: #999999;
  cursor: default;
  background-color: transparent;
}

.pagination ul > li:first-child > a,
.pagination ul > li:first-child > span {
  border-left-width: 1px;
  -webkit-border-bottom-left-radius: 4px;
          border-bottom-left-radius: 4px;
  -webkit-border-top-left-radius: 4px;
          border-top-left-radius: 4px;
  -moz-border-radius-bottomleft: 4px;
  -moz-border-radius-topleft: 4px;
}

.pagination ul > li:last-child > a,
.pagination ul > li:last-child > span {
  -webkit-border-top-right-radius: 4px;
          border-top-right-radius: 4px;
  -webkit-border-bottom-right-radius: 4px;
          border-bottom-right-radius: 4px;
  -moz-border-radius-topright: 4px;
  -moz-border-radius-bottomright: 4px;
}

.pagination-centered {
  text-align: center;
}

.pagination-right {
  text-align: right;
}

.pagination-large ul > li > a,
.pagination-large ul > li > span {
  padding: 11px 19px;
  font-size: 17.5px;
}

.pagination-large ul > li:first-child > a,
.pagination-large ul > li:first-child > span {
  -webkit-border-bottom-left-radius: 6px;
          border-bottom-left-radius: 6px;
  -webkit-border-top-left-radius: 6px;
          border-top-left-radius: 6px;
  -moz-border-radius-bottomleft: 6px;
  -moz-border-radius-topleft: 6px;
}

.pagination-large ul > li:last-child > a,
.pagination-large ul > li:last-child > span {
  -webkit-border-top-right-radius: 6px;
          border-top-right-radius: 6px;
  -webkit-border-bottom-right-radius: 6px;
          border-bottom-right-radius: 6px;
  -moz-border-radius-topright: 6px;
  -moz-border-radius-bottomright: 6px;
}

.pagination-mini ul > li:first-child > a,
.pagination-small ul > li:first-child > a,
.pagination-mini ul > li:first-child > span,
.pagination-small ul > li:first-child > span {
  -webkit-border-bottom-left-radius: 3px;
          border-bottom-left-radius: 3px;
  -webkit-border-top-left-radius: 3px;
          border-top-left-radius: 3px;
  -moz-border-radius-bottomleft: 3px;
  -moz-border-radius-topleft: 3px;
}

.pagination-mini ul > li:last-child > a,
.pagination-small ul > li:last-child > a,
.pagination-mini ul > li:last-child > span,
.pagination-small ul > li:last-child > span {
  -webkit-border-top-right-radius: 3px;
          border-top-right-radius: 3px;
  -webkit-border-bottom-right-radius: 3px;
          border-bottom-right-radius: 3px;
  -moz-border-radius-topright: 3px;
  -moz-border-radius-bottomright: 3px;
}

.pagination-small ul > li > a,
.pagination-small ul > li > span {
  padding: 2px 10px;
  font-size: 11.9px;
}

.pagination-mini ul > li > a,
.pagination-mini ul > li > span {
  padding: 0 6px;
  font-size: 10.5px;
}

.pager {
  margin: 20px 0;
  text-align: center;
  list-style: none;
  *zoom: 1;
}

.pager:before,
.pager:after {
  display: table;
  line-height: 0;
  content: "";
}

.pager:after {
  clear: both;
}

.pager li {
  display: inline;
}

.pager li > a,
.pager li > span {
  display: inline-block;
  padding: 5px 14px;
  background-color: #fff;
  border: 1px solid #ddd;
  -webkit-border-radius: 15px;
     -moz-border-radius: 15px;
          border-radius: 15px;
}

.pager li > a:hover {
  text-decoration: none;
  background-color: #f5f5f5;
}

.pager .next > a,
.pager .next > span {
  float: right;
}

.pager .previous > a,
.pager .previous > span {
  float: left;
}

.pager .disabled > a,
.pager .disabled > a:hover,
.pager .disabled > span {
  color: #999999;
  cursor: default;
  background-color: #fff;
}

.table-search{
  height: 35px;
  background-color: $light40Color;
  padding:5px;
  border-top: 1px solid $light20Color;
  border-bottom: 1px solid $light20Color;

  input[type=text]{
    background-color: rgba(255, 255, 255,0.7);
    color: $highlightColor;
    margin-top:4px;
    margin-left:10px;
    border: 2px solid $light20Color;
    border-radius: 1em;
    padding:4px 20px 4px 10px;   
  }

  input[type=text]:focus{
    background-color: white;
    color: $highlightColor;
    border: 2px solid $light20Color;
  }

  &.modal{
    margin: -10px -10px 10px -10px;
  }

}


.table-exception{
  height:100px;
  position:relative;
}


.table-exception-content{

  h1{
    font-size: 25px;
    float:left;
    margin-left:50px;
    font-weight: 300;
    color: $alertColor;
    width: 300px;
  } 
}

.table-exception-icon{
  font-size: 80px !important;
  opacity: 0.1;
  float: right;
  margin-right: 50px;
  color: $mainColor;
}


//DETAIL INFO + COLLAPSE

.detail-info{
  right:10px;
  left:40px;
  border:none;
  border-top: 2px dotted #DDD;
}


table.detail-info-table{

  th{
    border:none !important;
    color: $light10MainColor;
    text-transform:uppercase;
    text-align:left;
    padding-right:10px;
    vertical-align: top;
  }

  td{
    border:none !important;
    color: $highlightColor;
    font-weight: 700;
  }
}

.collapse-icon{
  width: 25px;
  height: 27px;
  color: $mainColor;
  opacity:0.5;
  cursor:pointer;
  padding-top:4px;

  i{
    line-height: 25px !important;
    font-size: 25px !important;
    /*padding-top: 2px;*/
  }

  &:hover{
    opacity:1;
    color: $highlightColor;
  }

  &.spin-clockwise{
    opacity:0.5;
    color: $mainColor;
    cursor:wait;
  }
}
////


.transfer-table-container{

  float:left;
  width:46%;
  margin:10px 2% 0px 2%;


  table td{
    line-height:14px;
  }

  .context-title{
    margin-left:0px;
    line-height:20px;  
    letter-spacing:0.3em;  
  }
}


.table-sort-icon{
  opacity:0.8;
  cursor:pointer;

  &:hover{
    color: $highlightColor !important;
    opacity: 1;
  }

  &[disabled]{
    opacity:0.2 !important;
    cursor:normal !important;
  }
}
